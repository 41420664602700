import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Card,
  Divider,
  Spinner,
  SpinnerSize,
  Colors,
} from '@blueprintjs/core'
import * as api from '../api'
import useTitle from '../utils/title'
import paths from '../utils/paths'
import idUtils from '../utils/id'
import authorUtils from '../utils/author'
import { WorkCard } from './works'
import { NotFound } from './404'
import {
  Title,
  Crumbline,
  IdTag,
  AuthorLink,
  MediumInfo,
  MiniInfo,
  MicroInfo,
  Notes,
} from '../components'

const authorsCrumbs = [
  { href: paths.index(), text: 'Reading the Holy Land' },
  { href: paths.authors(), text: 'Authors' },
]

export const Authors = () => {
  const [authors, setAuthors] = useState(null)

  useTitle('Authors')

  useEffect(() => {
    api.authors().then(data => setAuthors(data))
  }, [])

  let anon;
  let display = (
    <Spinner size={SpinnerSize.SMALL} />
  )


  if (Array.isArray(authors) && authors.length > 0) {
    display = authors.filter(a => !authorUtils.isAnonymous(a.name)).map(a => (
      <Card
        key={a.id}
        style={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: `1px solid ${Colors.BLACK}`,
          marginBottom: '1em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <MicroInfo
          title="Name"
          info={<AuthorLink author={a} />}
        />
        <MicroInfo
          title="LIfe dates"
          info={a.life_dates}
          none="Unknown"
          style={{textAlign: 'right'}}
        />
      </Card>
    ))
    anon = authors.find(a => authorUtils.isAnonymous(a.name))
  }

  if (anon) {
    display = (
      <div>
        <Card
          key={anon.id}
          style={{
            backgroundColor: 'transparent',
            marginBottom: '1em',
            borderStyle: 'dashed',
            borderWidth: '2px',
            borderColor: Colors.BLACK,
            boxShadow: 'none',
          }}
        >
          <AuthorLink author={anon} name="Works of unknown authorship" />
        </Card>
        {display}
      </div>
    )
  }

  return (
    <div>
      <Crumbline items={authorsCrumbs} />
      <Title>Authors</Title>
      <div style={{marginTop: '2.5em', marginBottom: '1em'}}>
        <div>{`${authors ? authors.length : 0} authors`}</div>
      </div>
      <div style={{maxWidth: '1024px'}}>
        {display}
      </div>
    </div>
  )
}

export const Author = () => {
  const { asig } = useParams()
  const [author, setAuthor] = useState(null)
  const [works, setWorks] = useState(null)
  const [notFound, setNotFound] = useState(false)

  useTitle(author ? author.name : 'Author')

  useEffect(() => {
    if (idUtils.isId(asig)) {
      api.author(asig).then(a => setAuthor(a))
    } else {
      api.authors({ query: { identifier: asig } }).then(auths => {
        if (!Array.isArray(auths) || auths.length !== 1) {
          setNotFound(true)
          return
        }

        const author = auths[0]
        // TODO make this a single query when we're able to expand things properly on general queries (not only id queries)
        api.author(author.id).then(a => setAuthor(a))
      })
    }
  }, [asig])

  useEffect(() => {
    if (author) {
      api.works({ query: { author: author.id } }).then(ws => setWorks(ws))
    }
  }, [author])

  const crumbs = [
    { href: paths.index(), text: 'Reading the Holy Land' },
    { href: paths.authors(), text: 'Authors' },
    { text: asig },
  ]
  
  if (notFound) {
    return <NotFound />
  }

  if (!author) {
    return <Spinner size={SpinnerSize.SMALL} />
  }

  const isAnon = authorUtils.isAnonymous(author.name)
  let workElems  = <Spinner size={SpinnerSize.SMALL} />
  if (Array.isArray(works)) {
    workElems = works.map(w => (
      <WorkCard key={w.id} work={w} style={{marginBottom: '1em'}} showByline={false} />
    ))
  }
  const title = isAnon ? 'Works of unknown authorship' : author.name

  return (
    <div>
      <Crumbline items={crumbs} />
      <Title>{title}</Title>
      { !isAnon &&
          <>
            <div style={{display: 'flex', marginBottom: '1.5em'}}>
              <MiniInfo
                title="Identifier"
                info={<IdTag id={author.identifier} />}
                style={{marginLeft: 0}}
              />
              <Divider />
              <MiniInfo
                title="Life dates"
                info={author.life_dates}
              />
            </div>
            <MediumInfo title="Short biography" info={<Notes notes={author.notes} />} />
          </>
      }
      <MediumInfo title="Works" info={workElems} />
    </div>
  )
}
