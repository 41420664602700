import { useContext, useRef, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Intent,
  Divider as BlueprintDivider,
  Classes,
  AnchorButton,
  Button,
  ButtonGroup,
  EditableText,
  Menu,
  MenuItem,
  InputGroup,
  Spinner,
  SpinnerSize,
  Card,
  Tag,
  Icon,
  IconSize,
  Colors,
  RangeSlider,
  Collapse,
  Checkbox,
} from '@blueprintjs/core'
import paths from '../utils/paths'
import idUtils from '../utils/id'
import dateUtils from '../utils/date'
import sortUtils from '../utils/sort'
import * as stringUtils from '../utils/string'
import { Tooltip2, Popover2 } from '@blueprintjs/popover2'
import * as popover from '@blueprintjs/popover2'
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css"
import {
  Title,
  Noner,
  IdTag,
  Crumbline,
  ID,
  MicroInfo,
  MiniInfo,
  MediumInfo,
  PopInfo,
  ScanButton,
  HoverableScanLink,
  FolioRange,
  DateRange,
  BibRefList,
  FolioInfo,
  Dimensions,
  Notes,
  AuthorLink,
  CardTitleLink,
  Trunc,
  WorkDate,
  BoxTag,
  HoverCardPill,
  SortSelect,
  InfoTip,
  Divider,
  DataLabel,
} from '../components'
import useTitle from '../utils/title'
import folioUtils from '../utils/folio'
import { startEndSorter } from '../utils/sort'
import * as api from '../api'
import urls from '../api/urls'
import * as d3 from 'd3'
import { DateRangeContext } from '../contexts/date-range'
import { startFnMaker, endFnMaker } from './works' // TODO the location of these fns should probably be moved to a more util-like place
import styles from '../components/styles.css'

const searchValues = ['signature', 'contents', 'identifier', 'notes', 'library', 'city']
const nonManuscriptSearchValues = ['incipit', 'explicit'] // currently these are both on the textual unit
const searchOptText = opt => {
  let val = opt
  if (opt === 'signature') {
    val = 'shelfmark'
  }
  return `in ${val}`
}

const SearchOption = ({ value, onChange }) => {
  const [opt, setOpt] = useState(value)

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(opt)
    }
  }, [opt, onChange])

  const items = searchValues.map(sv => (
    <MenuItem key={sv} text={searchOptText(sv)} onClick={() => setOpt(sv)} />
  ))

  return (
    <Popover2
      content={<Menu>{items}</Menu>}
      placement="bottom-end"
    >
      <Button minimal rightIcon="caret-down">
        {searchOptText(opt)}
      </Button>
    </Popover2>
  )
}

function useDebounce(value, wait) {
  const [debouncedVal, setDebouncedVal] = useState(value)
  useEffect(() => {
    const id = setTimeout(() => setDebouncedVal(value), wait)
    return () => clearTimeout(id)
  }, [value, wait])
  return debouncedVal
}

// given a folio number, returns all textual units that appear in that folio
// checks both recto and verso
const getTextualUnits = (folio, textualUnits) => {
  const r = new folioUtils.Folio(folio, folioUtils.RECTO)
  const v = new folioUtils.Folio(folio, folioUtils.VERSO)
  return folioUtils.getContainingRanges({
    ranges: textualUnits,
    folioStart: r,
    folioEnd: v,
    startExtractor: tu => tu.folioStart,
    endExtractor: tu => tu.folioEnd,
  })
}

const folioHeight = 20 
const folioWidth = 1
const folioSpace = 1
const folioY = 7
const bracketHeight = folioY
const bracketSpace = 2
const fontSize = 11
const heightPad = 4 + folioY
const widthPad = 2
const bracketColor = 'black'
const labelTopPad = 3
const textualUnitColor = '#53B3CB'
const hoverColor = '#f4d06f75' // previously rgba(254,241,96,.70)

const calcRenderWidth = folioRange => {
  if (!folioRange) {
    return 0
  }
  const nums = folioRange.enumerateFolioNumbers()
  // "- folioSpace" so that final folioSpace is not highlighted as it belongs to the next folio
  const width = (nums.length * (folioSpace + folioWidth)) - folioSpace
  return width < 0 ? 0 : width
}

const Folios = ({ manuscript, textualUnits, allWorks }) => {
  const [computedContents, setComputedContents] = useState([])
  const [computedTextualUnits, setComputedTextualUnits] = useState([])

  const totalFolios = manuscript.front_fly_leaves + manuscript.folios + manuscript.back_fly_leaves

  // TODO fly leaves are going to mess up what gets highlighted and what does not
  // we should move fly leaves to be their own groups outside of folios and highlight them differently
  // we should also parse contents and highlight them on the viz (with a hover on the contents menu)
  // show folio number at the beginning and end of manuscript
  // when hovering over a folio, display that folio number above it
  // add codico bar above folio with dates, support and material

  // expects to be passed a g as selection with renderWidth and folioRange available on d
  const drawBracket = selection => {
    // alternate brackets above and below folios so that they don't bump into each other
    selection
      .attr('transform', (d, i) => {
        const x = (d.folioRange.start.number-1) * (folioSpace+1)
        let transform = `translate(${x}, 0)`
        if (i % 2) {
          // bracketSpace * 2 to take into account the top and bottom bracket spacing
          transform = `translate(${x}, ${folioHeight + folioY + bracketHeight + (bracketSpace * 2)})rotate(180)scale(-1,1)`
        }
        return transform
      })

    selection.append('line')
      .attr('class', 'left')
      .attr('x1', 0)
      .attr('y1', 1)
      .attr('x2', 0)
      .attr('y2', bracketHeight)
      .attr('stroke', bracketColor)
      .attr('stroke-width', 1)
      .attr('stroke-linecap', 'square')
      .attr('stroke-dasharray', '2,2')
    selection.append('line')
      .attr('class', 'right')
      .attr('x1', d => d.renderWidth)
      .attr('y1', 1)
      .attr('x2', d => d.renderWidth)
      .attr('y2', bracketHeight)
      .attr('stroke', bracketColor)
      .attr('stroke-width', 1)
      .attr('stroke-linecap', 'square')
      .attr('stroke-dasharray', '2,2')
    selection.append('line')
      .attr('class', 'bar')
      .attr('x1', 0)
      .attr('y1', 1)
      .attr('x2', d => d.renderWidth)
      .attr('y2', 1)
      .attr('stroke', bracketColor)
      .attr('stroke-width', 1)
      .attr('stroke-linecap', 'square')
      .attr('stroke-dasharray', '2,2')
    return selection
  }

  const updateChart = () =>  {
    const svg = d3.select(svgRef.current)
    const viz = d3.select(vizRef.current)
    const folios = viz.selectAll('rect.folio')
      .data(d3.range(totalFolios).map(i => ({ i })))
    folios.enter().append('rect').attr('class', 'folio')
    folios
      .attr('width', folioWidth)
      .attr('height', folioHeight)
      .attr('y', folioY + bracketSpace)
      .attr('x', d => (d.i * (folioSpace+1)))
      .attr('fill', d => {
        // add one to d.i because range starts from 0 but folios start from 1
        const tus = getTextualUnits(d.i+1, computedTextualUnits)
        if (Array.isArray(tus) && tus.length > 0) {
          return textualUnitColor
        }
        return Colors.GRAY5
      })
    const corpusGroups = viz.selectAll('rect.textual-unit').data(computedTextualUnits)
    const corpusGroupsEnter = corpusGroups.enter().append('rect').attr('class', 'textual-unit')
    corpusGroupsEnter
      .attr('width', d => d.renderWidth)
      .attr('height', folioHeight)
      .attr('y', folioY + bracketSpace)
      .attr('x', d => {
        return (d.folioRange.start.number-1) * (folioSpace+1)
      })
      .attr('fill', 'transparent')
      .on('mouseover', (event, d) => {
        const title = allWorks[d.work] ? `${allWorks[d.work].title}` : `${d.title}`
        svg.select('text.label').text(`${title} (f. ${d.rangeLabel})`)
      })
      .on('mouseout', (event, d) => svg.select('text.label').text(''))
    const corpusBrackets = viz.selectAll('g.bracket').data(computedTextualUnits)
    const corpusBracketEnter = corpusBrackets.enter().append('g').attr('class', 'bracket')
    corpusBracketEnter.append('rect')
      .attr('class', 'bracket-hover-target')
      .attr('width', d => d.renderWidth)
      .attr('height', bracketHeight + bracketSpace) // cover the white space between folios and bracket
      .attr('fill', 'transparent')
      .on('mouseover', (event, d) => {
        const title = allWorks[d.work] ? `${allWorks[d.work].title}` : `${d.title}`
        svg.select('text.label').text(`${title} (f. ${d.rangeLabel})`)
      })
      .on('mouseout', (event, d) => svg.select('text.label').text(''))
    drawBracket(corpusBracketEnter)

    const contentGroups = viz.selectAll('rect.content').data(computedContents)
    const contentGroupsEnter = contentGroups.enter().append('rect').attr('class', 'content')
    contentGroupsEnter
      .attr('data-content', d => encodeURI(d.label))
      .attr('width', d => d.renderWidth)
      .attr('height', folioHeight)
      .attr('y', folioY + bracketSpace)
      .attr('x', d => {
        return (d.folioRange.start.number-1) * (folioSpace + 1)
      })
      .attr('fill', 'transparent')
      .on('mouseover', (event, d) => {
        //d3.select(event.target).attr('fill', '')
        d3.select(event.target).attr('fill', hoverColor)
        d3.select(`.content-bracket[data-content="${encodeURI(d.label)}"]`).selectAll('line').attr('stroke', 'black')
        svg.select('text.label').text(`${d.label}`)
      })
      .on('mouseout', (event, d) => {
        d3.select(event.target).attr('fill', 'transparent')
        d3.select(`.content-bracket[data-content="${encodeURI(d.label)}"]`).selectAll('line').attr('stroke', Colors.GRAY5)
        svg.select('text.label').text('')
      })
    const contentBrackets = viz.selectAll('g.content-bracket').data(computedContents)
    const contentBracketsEnter = contentBrackets.enter().append('g')
      .attr('class', 'content-bracket')
      .attr('data-content', d => encodeURI(d.label))
    contentBracketsEnter.append('rect')
      .attr('class', 'content-bracket-hover-target')
      .attr('width', d => d.renderWidth)
      .attr('height', bracketHeight + bracketSpace) // cover the white space between folios and bracket
      .attr('fill', 'transparent')
      .on('mouseover', (event, d) => {
        d3.select(`[data-content="${encodeURI(d.label)}"]`).attr('fill', hoverColor)
        d3.select(`.content-bracket[data-content="${encodeURI(d.label)}"]`).selectAll('line').attr('stroke', 'black')
        svg.select('text.label').text(`${d.label}`)
      })
      .on('mouseout', (event, d) => {
        d3.select(`[data-content="${encodeURI(d.label)}"]`).attr('fill', 'transparent')
        d3.select(`.content-bracket[data-content="${encodeURI(d.label)}"]`).selectAll('line').attr('stroke', Colors.GRAY5)
        svg.select('text.label').text('')
      })
    drawBracket(contentBracketsEnter)
      .selectAll('line').attr('stroke', Colors.GRAY5)

  }

  const svgRef = useRef()
  const vizRef = useRef()
  useEffect(() => {
    if (totalFolios && computedTextualUnits && computedContents) {
      updateChart()
    }
  }, [totalFolios, computedTextualUnits, computedContents])

  useEffect(() => {
    if (textualUnits) {
      // pre-compute render width and range of textual units as it is used in multiple places above
      setComputedTextualUnits(textualUnits.map(tu => {
        const range = new folioUtils.FolioRange(tu.folioStart, tu.folioEnd)
        return {
          ...tu,
          folioRange: range,
          renderWidth: calcRenderWidth(range),
          rangeLabel: range.toString(),
        }
      }))
    }
  }, [textualUnits])

  useEffect(() => {
    if (manuscript.contents && computedTextualUnits.length > 0) {
      const cs = manuscript.contents.split(';')
      const computed = cs.map(c => {
        const res = c.trim().toLowerCase().match(/\((?<folios>[^)]+)\)$/) // capture folios in parentheses
        if (res?.groups.folios) {
          const g = res.groups.folios.replace(/(f|\.| )/g, '')
          const folioRange = folioUtils.parseFolioRange(res.groups.folios.replace(/(f|\.| )/g, ''))
          if (folioRange) {
            return {
              label: c,
              rangeLabel: folioRange.toString(),
              folioRange,
              renderWidth: calcRenderWidth(folioRange),
            }
          }
        }
        return null
      }).filter(Boolean)

      // corpus textual units are also included in contents.
      // filter out contents that are in our corpus as they will be rendered as textual units and we don't want overlaps
      const tus = computedTextualUnits.map(tu => tu.folioRange.toString())
      // string representation is enough to check equality
      setComputedContents(computed.filter(c => {
        return !tus.includes(c.folioRange.toString())
      }))
    }
  }, [manuscript.contents, computedTextualUnits])

  if (totalFolios === 0) {
    return (
      <svg height={folioHeight + heightPad + fontSize + (bracketHeight * 2)} width={1024 + widthPad} ref={svgRef}>
      </svg>
    )
  }

  return (
    <svg height={folioHeight + heightPad + fontSize + (bracketHeight * 2) + 1} width={1024 + widthPad} ref={svgRef}>
      {/* "+ 1" for a bit more padding */}
      {/* shift right by 2 so items are not cut off on the left */}
      <g className="viz" ref={vizRef} transform="translate(2,0)"></g>
      <text className="label" y={folioY + folioHeight + fontSize + labelTopPad + bracketHeight} style={{fontSize}}></text>
    </svg>
  )
}

// smaller info contained in a group of buttons
export const ManuscriptInfo = ({ manuscript }) => {
  const ms = manuscript

  return (
    <ButtonGroup>
      <PopInfo title="Contents" cardStyle={{maxWidth: 'inherit'}} placement="bottom-end">
        <Contents contents={ms.contents} />
      </PopInfo>
      <BlueprintDivider />
      <ScanButton scanLinks={ms.scan_link} />
    </ButtonGroup>
  )
}

// dateRangeMap is not needed if dateRange object is expanded on the codico
export const ManuscriptCard = ({ manuscript, codicos, textualUnits, allWorks, dateRangeMap={} }) => {
  const [cods, setCods] = useState(null)
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [dateRange, setDateRange] = useState(null) // if dateRange is set, prefer this as the label over minDate/maxDate
  const totalWorks = textualUnits ? textualUnits.length : 0

  useEffect(() => {
    // codicos could have their dates specificed by either start/end or a dateRange id
    // if dateRange id we can use the dateRangeMap to look up dates
    if (Array.isArray(codicos) && codicos.length > 0) {
      const cds = codicos.map(c => {
        if (c.date_range) {
          // c.date_range can either be a date range id or an expanded date range object
          if (typeof c.date_range === 'object') {
            return { start: c.date_range.start, end: c.date_range.end, dateRange: c.date_range }
          }

          // we fall to here if dateRange is not expanded and dateRangeMap provided
          const dr = dateRangeMap[c.date_range]
          if (dr) {
            return { start: dr.start, end: dr.end, dateRange: dr }
          }
          // we fall out if the date is not a dateRange
        }
        return { start: c.date_start, end: c.date_end }
      })

      const sorted = cds.sort(startEndSorter)

      // if we only have one codico, and that codico has a dateRange, use that date range label
      if (sorted.length === 1 && sorted[0].dateRange) {
        setDateRange(sorted[0].dateRange)
      } else {
        setMinDate(sorted[0].start)
        setMaxDate(sorted[sorted.length-1].end)
      }
    }
  }, [codicos])

  const ms = manuscript
  const hasFolios = ms.folios > 0
  return (
    <Card
      style={{
        marginBottom: '30px',
        backgroundColor: 'rgb(250, 240, 216)',
        border: `1px solid ${Colors.BLACK}`,
        padding: '20px 0 0 0',
        boxShadow: 'none',
      }}
    >
      <div style={{paddingLeft: '20px', paddingRight: '20px'}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <MicroInfo
            title="Shelfmark"
            info={
              <CardTitleLink to={paths.manuscripts(ms.identifier)}>
                <Trunc text={ms.signature} />
              </CardTitleLink>
            }
          />
          <MicroInfo
            title="Date"
            style={{textAlign: 'right'}}
            info={
              <DateRange start={minDate} end={maxDate} dateRange={dateRange} dateRangeMap={dateRangeMap} />
            }
          />
        </div>
        <div style={{marginTop: '15px'}}>
          <Folios manuscript={ms} textualUnits={textualUnits} allWorks={allWorks} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: `1px solid ${Colors.BLACK}`,
        }}
      >
        <div
          style={{
            padding: '10px 20px',
            minHeight: '40px', // minHeight 40px (here and below) is used to accommodate the underline on fly leaves
          }}
        >
          {totalWorks} work{totalWorks === 1 ? '' : 's'} in <FolioInfo folios={ms.folios} frontFlyLeaves={ms.front_fly_leaves} backFlyLeaves={ms.back_fly_leaves} zeroFolioText="Unknown number of folios" /> { hasFolios ? 'folios' : '' }
        </div>
        <div
          style={{
            display: 'flex',
            borderLeft: `1px solid ${Colors.BLACK}`,
            minHeight: '40px',
          }}
        >
          <ManuscriptInfo2 contents={ms.contents} scanLinks={ms.scan_link} />
        </div>
      </div>
    </Card>
  )
}

export const ManuscriptInfo2 = ({ contents, scanLinks }) => {
  return (
    <>
      <PopInfo
        render={() => (
          <div
            className="hoverable"
            style={{minHeight: '40px', padding: '10px 20px', borderRight: `1px solid ${Colors.BLACK}`, cursor: 'pointer'}}
          >
            Contents
          </div>
        )}
        cardStyle={{maxWidth: 'inherit'}}
        placement="bottom-end"
      >
        <Contents contents={contents} />
      </PopInfo>
      <HoverableScanLink
        scanLinks={scanLinks}
        style={{
          minHeight: '40px',
          padding: '10px 20px',
          minWidth: '100px', // so that the containing box for "Scan" and "No scan" are the same width
          textAlign: 'center',
          textDecoration: 'none',
          cursor: scanLinks?.length > 0 ? 'pointer' : null,
          color: Colors.BLACK,
        }}
      />
    </>
  )
}

const sortShelfmark = mss => {
  if (!Array.isArray(mss)) {
    return mss
  }
  const next = [...mss]
  next.sort((a, b) => a.signature.localeCompare(b.signature))
  return next
}

const sortCodicoDate = (codicoMap, dateRanges, mss) => {
  if (!Array.isArray(mss)) {
    return mss
  }

  const startFn = startFnMaker({
    codicosFn: ms => codicoMap[ms.id],
    dateRanges,
  })
  const endFn = endFnMaker({
    codicosFn: ms => codicoMap[ms.id],
    dateRanges,
  })

  const dateSorter = sortUtils.makeDateSorter({ startFn, endFn })
  const next = [...mss]
  next.sort(dateSorter)
  return next
}

export const Manuscripts = () => {
  const maxWidth = '1024px'
  const [mss, setMss] = useState(null)
  const [totalMss, setTotalMss] = useState(0)
  const [textUnitMap, setTextUnitMap] = useState({})
  const [codicoMap, setCodicoMap] = useState({})
  const [workMap, setWorkMap] = useState({})
  const [dateRangeMap, setDateRangeMap] = useState({}) // map of date range id to date range
  const [filtering, setFiltering] = useState(false)
  const [filters, setFilters] = useState(null)
  const [sort, setSort] = useState('Date')
  const { dateRanges } = useContext(DateRangeContext)

  useTitle('Manuscripts')

  // sort effect
  useEffect(() => {
    if (sort === 'Date') {
      setMss(prev => sortCodicoDate(codicoMap, dateRanges, mss))
    } else if (sort === 'Shelfmark') {
      setMss(prev => sortShelfmark(mss))
    }
  }, [sort, mss, codicoMap, dateRanges])

  // effect for getting data
  useEffect(() => {
    api.dateRanges()
      .then(ranges => {
        const drm = ranges.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {})
        setDateRangeMap(drm)
      })

    api.manuscripts({}).then(fetchedMss => {
      setMss(fetchedMss)
      setTotalMss(fetchedMss.length)
      const ids = fetchedMss.map(ms => ms.id)

      // get textual units
      api.textualUnits({ query: { 'manuscript.in': ids } })
        .then(textunits => {
          const tum = textunits.reduce((acc, curr) => {
            if (!acc[curr.manuscript]) {
              acc[curr.manuscript] = []
            }
            acc[curr.manuscript].push(curr)
            return acc
          }, {})
          setTextUnitMap(tum)
          return textunits
        })

      // get all works
      api.works()
        .then(works => {
          const wm = works.reduce((acc, curr) => {
            acc[curr.id] = curr
            return acc
          }, {})
          setWorkMap(wm)
        })

      // get codicological units
      api.codicologicalUnits({ query: { 'manuscript.in': ids } })
        .then(codicos => {
          const cum = codicos.reduce((acc, curr) => {
            if (!acc[curr.manuscript]) {
              acc[curr.manuscript] = []
            }
            acc[curr.manuscript].push(curr)
            return acc
          }, {})
          setCodicoMap(cum)
        })
      
    })
  }, [])

  const getWorksFilters = filters => {
    const workKeys = Object.keys(filters).filter(k => k.startsWith('work.'))
    if (workKeys.length === 0) {
      return null
    }
    const workFilters = workKeys.reduce((acc, curr) => {
      acc[curr] = filters[curr]
      return acc
    }, {})
    return Object.keys(workFilters).length === 0 ? null : workFilters
  }

  const getCodicoFilters = filters => {
    const codicoKeys = Object.keys(filters).filter(k => k.startsWith('codicological_unit.'))
    if (codicoKeys.length === 0) {
      return null
    }
    const codicoFilters = codicoKeys.reduce((acc, curr) => {
      // remove "codicological_unit." from the key so that this can be used to query
      // directly on a codicological unit
      const parts = curr.split('.')
      const edited = parts.slice(1, parts.length).join('.')
      acc[edited] = filters[curr]
      return acc
    }, {})
    return Object.keys(codicoFilters).length === 0 ? null : codicoFilters
  }

  const getTextualUnitFilters = filters => {
    const prefixes = nonManuscriptSearchValues.map(nmsv => `${nmsv}.`)
    const tuFilterKeys = Object.keys(filters)
      .filter(k =>
        prefixes.reduce((acc, curr) => acc || k.startsWith(curr), false)
      )
    const tuFilters = Object.keys(filters).reduce((acc, curr) => {
      if (tuFilterKeys.includes(curr)) {
        acc[curr] = filters[curr]
      }
      return acc
    }, {})
    return Object.keys(tuFilters).length === 0 ? null : tuFilters
  }

  // manuscript filters are the "main" filters. here all we have to do is remove filters from other tables
  // i.e. filters that do not reference the manuscript table
  const getManuscriptFilters = filters => {
    const nonManuscriptPrefixes = [...nonManuscriptSearchValues.map(nmsv => `${nmsv}.`), 'codicological_unit.', 'work.']
    const nonManuscriptKeys = Object.keys(filters)
      .filter(k =>
        nonManuscriptPrefixes.reduce((acc, curr) => acc || k.startsWith(curr), false)
      )
    if (nonManuscriptKeys.length === 0) {
      return filters
    }
    const manuscriptFilters = Object.keys(filters).reduce((acc, curr) => {
      if (!(nonManuscriptKeys.includes(curr))) {
        acc[curr] = filters[curr]
      }
      return acc
    }, {})
    return Object.keys(manuscriptFilters).length === 0 ? null : manuscriptFilters
  }

  // TODO update URL here.
  //const updateUrlFilters = filters => {
  //  if (!filters) {
  //    // TODO clear filters
  //    return
  //  }
  //  // serialize filters with qs or query-string package
  //  // window.location.replace(0
  //}

  const handleFilterChange = async filters => {
    setFilters(filters)

    // TODO
    //updateUrlFilters(filters)

    if (!filters) {
      resetManuscripts()
      return
    }

    setFiltering(true)
    // handle codicological unit filters (codico filters are used to filter the date of a manuscript) and work filters differently for now
    // because we can't do deep model queries (yet)

    let manuscriptIds = []

    const codicoFilters = getCodicoFilters(filters)
    if (codicoFilters) {
      // query codicos by both date_start/date_end and date_range model beacuse codicos can have either a specific date range or a named date range
      const startFilterKey = Object.keys(codicoFilters).find(k => k.startsWith('date_start'))
      const endFilterKey = Object.keys(codicoFilters).find(k => k.startsWith('date_end'))
      // the keys may have queries appended by '.'; we want to replace the codico key name with date range key names while preserving the queries at the end
      const drStartKey = ['start'].concat(startFilterKey.split('.').filter((_, i) => i > 0)).join('.')
      const drEndKey = ['end'].concat(endFilterKey.split('.').filter((_, i) => i > 0)).join('.')
      // get date ranges that affect this query
      const drs = await api.dateRanges({
        query: { [drStartKey]: codicoFilters[startFilterKey], [drEndKey]: codicoFilters[endFilterKey] },
      })
      const drIds = drs.map(dr => dr.id)

      // codicos filtered by date range on codico
      const codicos = await api.codicologicalUnits({ query: codicoFilters })
      // codicos filtered by a named date range
      const drCodicos = await api.codicologicalUnits({ query: { ['date_range.in']: drIds }})

      // when we intersect with existing filters, filters are AND'd across different filters
      let msids = codicos.map(c => c.manuscript)
      msids.push(...drCodicos.map(c => c.manuscript))
      msids = Array.from(new Set(msids))
      manuscriptIds = manuscriptIds.length === 0 ? msids : msids.filter(mid => manuscriptIds.includes(mid))
    }

    const workFilters = getWorksFilters(filters)
    if (workFilters) {
      const tus = await api.textualUnits({ query: workFilters })
      // when we intersect with existing filters, filters are AND'd across different filters
      const msids = tus.map(t => t.manuscript)
      manuscriptIds = manuscriptIds.length === 0 ? msids : msids.filter(mid => manuscriptIds.includes(mid))
    }

    const tuFilters = getTextualUnitFilters(filters)
    if (tuFilters) {
      const tus = await api.textualUnits({ query: tuFilters })
      // when we intersect with existing filters, filters are AND'd across different filters
      const msids = tus.map(t => t.manuscript)
      manuscriptIds = manuscriptIds.length === 0 ? msids : msids.filter(mid => manuscriptIds.includes(mid))
    }

    const manuscriptFilters = getManuscriptFilters(filters)
    const query = { ...manuscriptFilters }
    if (manuscriptIds.length > 0) {
      query['id.in'] = manuscriptIds
    }
    if (Object.keys(query).length > 0) {
      try {
        const fetchedMss = await api.manuscripts({ query })
        setMss(fetchedMss)
      }
      catch (err) {
        console.log(err)
      }
    } else {
      // if the query is empty, but there are filters selected, then no results would be returned,
      // (so we can short-circuit here to displaying no results)
      setMss([])
    }

    setFiltering(false)
  }

  const resetManuscripts = () => {
    setFiltering(true)
    api.manuscripts({})
      .then(fetchedMss => {
        setFiltering(false)
        setMss(fetchedMss)
      })
      .catch(() => setFiltering(false))
  }

  const clearFilters = () => {
    setFilters(null)
    resetManuscripts()
  }

  let elems = (
    <Spinner size={SpinnerSize.SMALL} />
  )

  if (mss && mss.length > 0 && dateRangeMap) {
    elems = mss.map(ms => {
      return (
        <ManuscriptCard
          key={ms.id}
          manuscript={ms}
          codicos={codicoMap[ms.id]}
          textualUnits={textUnitMap[ms.id]}
          allWorks={workMap}
          dateRangeMap={dateRangeMap}
        />
      )
    })
  } else if (Array.isArray(mss) && dateRangeMap && !filtering) {
    elems = (
      <i>No manuscripts match that query</i>
    )
  }

  const mssCrumbs = [
    { href: paths.index(), text: 'Reading the Holy Land'},
    { text: 'Manuscripts'},
  ]

  const filterSummary = msCountSummary({
    total: totalMss,
    current: mss ? mss.length : 0,
    isFiltered: !!filters,
    loading: filtering,
  })

  return (
    <div>
      <Crumbline items={mssCrumbs} />
      <Title>Manuscripts</Title>
      <FilterBar
        filters={filters}
        onChange={handleFilterChange}
        onClearFilters={clearFilters}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '2.5em',
          marginBottom: '1em',
          maxWidth,
        }}
      >
        {filterSummary}
      <div style={{display: 'flex', alignItems: 'center'}}>
        <InfoTip
          containerStyle={{marginRight: '1em'}}
          contentStyle={{maxWidth: '250px'}}
          content="List ordered by date of codex production. For dates of specific manuscript sections, search by works, or look for specific textual units."
        />
        <SortSelect
          value={sort}
          options={['Date', 'Shelfmark']}
          onChange={e => setSort(e.target.value)}
        />
      </div>
      </div>
      <div style={{maxWidth}}>
        {elems}
      </div>
    </div>
  )
}

const msCountSummary = ({ total, current, isFiltered, loading }) => {
  if (loading) {
    return 'Loading...'
  }
  const label = stringUtils.plur(total, 'manuscript', 'manuscripts')
  if (!isFiltered) {
    return `${total} ${label}`
  }
  return `${current} of ${total} ${label}`
}

const FilterTag = ({ name, filterSummary, filterMenu, onRemove, onPopoverClose }) => {
  const [openFilterMenu, setOpenFilterMenu] = useState(false)

  const remover = e => {
    if (typeof onRemove === 'function') {
      onRemove()
    }
  }

  const hasFilters = !!filterSummary
  const hasRemove = typeof onRemove === 'function'
  const leftIcon = hasFilters ? <Icon icon="small-cross" onClick={remover} style={{fontWeight: 'normal'}}/> : <Icon icon="small-plus" style={{fontWeight: 'normal'}}/>


  return (
    <Popover2
      content={filterMenu}
      placement="bottom-start"
      onClose={onPopoverClose}
    >
      <Tag
        minimal
        round
        interactive
        style={{
          borderColor: Colors.BLACK,
          borderWidth: '1px',
          borderRadius: '2px',
          borderStyle: hasFilters ? 'solid' : 'dashed',
          color: Colors.BLACK,
          backgroundColor: 'transparent',
        }}
        icon={leftIcon}
        intent={hasFilters ? Intent.PRIMARY : null}
      >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span>{name}</span>
            { hasFilters && 
                <span
                  style={{borderLeft: `1px solid ${Colors.BLACK}`, paddingLeft: '10px', marginLeft: '10px'}}
                >
                  {filterSummary ?
                    <span>{filterSummary}</span>
                    :
                    null
                  }
                </span>
            }
          </div>
      </Tag>
    </Popover2>
  )
}

// a generalized filter summary that will take in an array of strings and truncate them into a summary
const truncatedArrayFilterSummary = ({ items, maxChars=10, joiner=' or '}) => {
  if (Array.isArray(items) && items.length > 0) {
    return items.map(itm => {
      let content = (
        <span style={{fontWeight: 'bold'}}>
          {stringUtils.ellipse(itm, maxChars)}
        </span>
      )
      
      if (itm.length > maxChars) {
        content = (
          <Tooltip2 content={itm} placement="bottom">{content}</Tooltip2>
        )
      }
      return <span key={itm}>{content}</span>
    }).reduce((acc, curr) => [acc, joiner, curr])
  }
}

const ExplicitFilter = ({ explicit, onChange }) => {
  const [appliedExplicit, setAppliedExplicit] = useState(explicit)
  const [inputtedExplicit, setInputtedExplicit] = useState(explicit)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  useEffect(() => {
    setAppliedExplicit(explicit)
  }, [explicit])

  const onApply = () => {
    changer({ explicit: inputtedExplicit })
  }

  const onRemove = () => {
    setInputtedExplicit(null)
    setAppliedExplicit(null)
    changer({ explicit: null })
  }

  const filterMenu = (
    <div style={{padding: '1.5em'}}>
      <div style={{marginBotton: '1em'}}>
        <InputGroup
          defaultValue={appliedExplicit}
          value={inputtedExplicit}
          onChange={e => setInputtedExplicit(e.target.value)}
          leftIcon="search"
          placeholder="Search explicit"
          onKeyDown={e => e.key === 'Enter' && onApply()}
        />
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '1em'}}>
        <Button small onClick={onRemove} style={{width: '100%', marginRight: '.5em'}}>
          Clear
        </Button>
        <Button small intent={Intent.PRIMARY} style={{width: '100%'}} onClick={onApply}>
          Apply
        </Button>
      </div>
    </div>
  )

  const summaryItems = typeof appliedExplicit === 'string' && appliedExplicit.length > 0 ? [appliedExplicit] : []

  return (
    <FilterTag
      name="Explicit"
      filterSummary={truncatedArrayFilterSummary({ items: summaryItems })}
      filterMenu={filterMenu}
      onRemove={onRemove}
    />
  )
}

const IncipitFilter = ({ incipit, onChange }) => {
  const [appliedIncipit, setAppliedIncipit] = useState(incipit)
  const [inputtedIncipit, setInputtedIncipit] = useState(incipit)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  useEffect(() => {
    setAppliedIncipit(incipit)
  }, [incipit])

  const onApply = () => {
    changer({ incipit: inputtedIncipit })
  }

  const onRemove = () => {
    setInputtedIncipit(null)
    setAppliedIncipit(null)
    changer({ incipit: null })
  }

  const filterMenu = (
    <div style={{padding: '1.5em'}}>
      <div style={{marginBotton: '1em'}}>
        <InputGroup
          defaultValue={appliedIncipit}
          value={inputtedIncipit}
          onChange={e => setInputtedIncipit(e.target.value)}
          leftIcon="search"
          placeholder="Search incipit"
          onKeyDown={e => e.key === 'Enter' && onApply()}
        />
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '1em'}}>
        <Button small onClick={onRemove} style={{width: '100%', marginRight: '.5em'}}>
          Clear
        </Button>
        <Button small intent={Intent.PRIMARY} style={{width: '100%'}} onClick={onApply}>
          Apply
        </Button>
      </div>
    </div>
  )

  const summaryItems = typeof appliedIncipit === 'string' && appliedIncipit.length > 0 ? [appliedIncipit] : []

  return (
    <FilterTag
      name="Incipit"
      filterSummary={truncatedArrayFilterSummary({ items: summaryItems })}
      filterMenu={filterMenu}
      onRemove={onRemove}
    />
  )
}

const CityFilter = ({ cities, onChange }) => {
  const [allCities, setAllCities] = useState([])
  const [selectedCities, setSelectedCities] = useState([])
  const citiesLoaded = Array.isArray(allCities) && allCities.length > 0
  const changer = typeof onChange === 'function' ? onChange : () => {}

  // sets selectedCities to the inputted cities
  const resetCities = () => {
    if (Array.isArray(cities) && cities.length > 0) {
      setSelectedCities(cities)
    } else {
      setSelectedCities([])
    }
  }

  useEffect(() => {
    api.cities().then(cs => setAllCities(cs))
  }, [])

  useEffect(() => {
    resetCities()
  }, [cities])

  const handleChange = city => e => {
    if (e.target.checked) {
      // add to selected cities if not already present
      if (!selectedCities.includes(city)) {
        setSelectedCities(prev => [...prev, city])
      }
    } else {
      // remove from selected city
      setSelectedCities(prev => prev.filter(l => l !== city))
    }
  }

  const onApply = () => {
    changer({ cities: selectedCities })
  }

  const cityCheckboxes = allCities.map(c => (
    <Checkbox
      key={c}
      checked={selectedCities.includes(c)}
      label={c}
      onChange={handleChange(c)}
    />
  ))

  const cityElems = citiesLoaded ? cityCheckboxes : 'Loading...'
  const filterMenu = (
    <div style={{padding: '1.5em'}}>
      <Button
        small
        style={{width: '100%'}}
        intent={Intent.PRIMARY}
        onClick={onApply}
      >
        Apply
      </Button>
      <div style={{marginTop: '1em', marginBottom: '1em'}}>
        {cityElems}
      </div>
      <Button
        small
        style={{width: '100%'}}
        intent={Intent.PRIMARY}
        onClick={onApply}
      >
        Apply
      </Button>
    </div>
  )

  return (
    <FilterTag
      name="City"
      filterSummary={truncatedArrayFilterSummary({ items: selectedCities })}
      filterMenu={filterMenu}
      onPopoverClose={resetCities}
      onRemove={() => {
        setSelectedCities([])
        changer({ cities: [] })
      }}
    />
  )
}

const LibraryFilter = ({ libraries, onChange }) => {
  const [allLibraries, setAllLibraries] = useState([])
  const [selectedLibraries, setSelectedLibraries] = useState([])
  const librariesLoaded = Array.isArray(allLibraries) && allLibraries.length > 0
  const changer = typeof onChange === 'function' ? onChange : () => {}

  // sets selectedLibraries to the inputted libraries
  const resetLibraries = () => {
    if (Array.isArray(libraries) && libraries.length > 0) {
      setSelectedLibraries(libraries)
    } else {
      setSelectedLibraries([])
    }
  }

  useEffect(() => {
    api.libraries().then(ls => setAllLibraries(ls))
  }, [])

  useEffect(() => {
    resetLibraries()
  }, [libraries])

  const handleChange = library => e => {
    if (e.target.checked) {
      // add to selected libraries if not already present
      if (!selectedLibraries.includes(library)) {
        setSelectedLibraries(prev => [...prev, library])
      }
    } else {
      // remove from selected libraries
      setSelectedLibraries(prev => prev.filter(l => l !== library))
    }
  }

  const onApply = () => {
    changer({ libraries: selectedLibraries })
  }

  const libraryCheckboxes = allLibraries.map(l => (
    <Checkbox
      key={l}
      checked={selectedLibraries.includes(l)}
      label={l}
      onChange={handleChange(l)}
    />
  ))

  const libraryElems = librariesLoaded ? libraryCheckboxes : 'Loading...'
  const filterMenu = (
    <div style={{padding: '1.5em'}}>
      <Button
        small
        style={{width: '100%'}}
        intent={Intent.PRIMARY}
        onClick={onApply}
      >
        Apply
      </Button>
      <div style={{marginTop: '1em', marginBottom: '1em'}}>
        {libraryElems}
      </div>
      <Button
        small
        style={{width: '100%'}}
        intent={Intent.PRIMARY}
        onClick={onApply}
      >
        Apply
      </Button>
    </div>
  )

  return (
    <FilterTag
      name="Library"
      filterSummary={truncatedArrayFilterSummary({ items: selectedLibraries })}
      filterMenu={filterMenu}
      onPopoverClose={resetLibraries}
      onRemove={() => {
        setSelectedLibraries([])
        changer({ libraries: [] })
      }}
    />
  )
}

const ThemeFilter = ({ themes, onChange }) => {
  const [allThemes, setAllThemes] = useState([])
  const [selectedThemes, setSelectedThemes] = useState([])
  const themesLoaded = Array.isArray(allThemes) && allThemes.length > 0
  const changer = typeof onChange === 'function' ? onChange : () => {}

  // sets selectedThemes to the inputted themes
  const resetThemes = () => {
    if (Array.isArray(themes) && themes.length > 0) {
      setSelectedThemes(themes)
    } else {
      setSelectedThemes([])
    }
  }

  useEffect(() => {
    api.thematicTags().then(tts => setAllThemes(tts))
  }, [])

  useEffect(() => {
    resetThemes()
  }, [themes])

  const handleChange = theme => e => {
    if (e.target.checked) {
      // add to selected themes if not already present
      if (!selectedThemes.includes(theme)) {
        setSelectedThemes(prev => [...prev, theme])
      }
    } else {
      // remove from selected themes
      setSelectedThemes(prev => prev.filter(t => t !== theme))
    }
  }

  const onApply = () => {
    changer({ themes: selectedThemes })
  }

  const themeCheckboxes = allThemes.map(t => (
    <Checkbox
      key={t}
      checked={selectedThemes.includes(t)}
      label={t}
      onChange={handleChange(t)}
    />
  ))

  const themeElems = themesLoaded ? themeCheckboxes : 'Loading...'
  const filterMenu = (
    <div style={{padding: '1.5em'}}>
      <Button
        small
        style={{width: '100%'}}
        intent={Intent.PRIMARY}
        onClick={onApply}
      >
        Apply
      </Button>
      <div style={{marginTop: '1em', marginBottom: '1em'}}>
        {themeElems}
      </div>
      <Button
        small
        style={{width: '100%'}}
        intent={Intent.PRIMARY}
        onClick={onApply}
      >
        Apply
      </Button>
    </div>
  )

  return (
    <FilterTag
      name="Theme"
      filterSummary={truncatedArrayFilterSummary({ items: selectedThemes })}
      filterMenu={filterMenu}
      onPopoverClose={resetThemes}
      onRemove={() => {
        setSelectedThemes([])
        changer({ themes: [] })
      }}
    />
  )
}

// works is expected to be an array of work ids
const WorkFilter = ({ works, onChange }) => {
  const [allWorks, setAllWorks] = useState([])
  const [selectedWorks, setSelectedWorks] = useState([])
  const worksLoaded = Array.isArray(allWorks) && allWorks.length > 0
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const selectedWorksMap = selectedWorks.reduce((acc, curr) => {
    acc[curr.id] = curr
    return acc
  }, {})
  const allWorksMap = allWorks.reduce((acc, curr) => {
    acc[curr.id] = curr
    return acc
  }, {})

  // sets selectedWorks to the inputted works
  const resetWorks = () => {
    if (Array.isArray(works) && works.length > 0) {
      setSelectedWorks(works.map(wid => allWorksMap[wid]))
    } else {
      setSelectedWorks([])
    }
  }

  useEffect(() => {
    api.works({ query: { _ex: 'author' }}).then(ws => setAllWorks(ws))
  }, [])

  useEffect(() => {
    resetWorks()
  }, [works])

  const handleChange = work => e => {
    if (e.target.checked) {
      // add to selected works if not already present
      if (!selectedWorksMap.hasOwnProperty(work.id)) {
        setSelectedWorks(prev => [...prev, work])
      }
    } else {
      // remove from selected works
      setSelectedWorks(prev => prev.filter(w => w.id !== work.id))
    }
  }

  const onApply = () => {
    changer({ works: selectedWorks })
  }

  const workCheckboxes = allWorks.map(w => (
    <Checkbox
      key={w.id}
      checked={selectedWorksMap.hasOwnProperty(w.id)}
      label={w.title}
      onChange={handleChange(w)}
    >
      <div style={{color: Colors.GRAY3}}>by {w.author.name}</div>
    </Checkbox>
  ))

  const workElems = worksLoaded ? workCheckboxes : 'Loading...'
  const filterMenu = (
    <div style={{padding: '1.5em'}}>
      <Button
        small
        style={{width: '100%'}}
        intent={Intent.PRIMARY}
        onClick={onApply}
      >
        Apply
      </Button>
      <div style={{marginTop: '1em', marginBottom: '1em'}}>
        {workElems}
      </div>
      <Button
        small
        style={{width: '100%'}}
        intent={Intent.PRIMARY}
        onClick={onApply}
      >
        Apply
      </Button>
    </div>
  )

  const summaryItems = Array.isArray(selectedWorks) ? selectedWorks.map(sw => sw.title) : []

  return (
    <FilterTag
      name="Work"
      filterSummary={truncatedArrayFilterSummary({ items: summaryItems })}
      filterMenu={filterMenu}
      onPopoverClose={resetWorks}
      onRemove={() => {
        setSelectedWorks([])
        changer({ works: [] })
      }}
    />
  )
}

const dateFilterSummary = ({ start, end }) => {
  if (!start && !end) {
    return null
  }
  if (start === end) {
    return start
  }
  return <span style={{fontWeight: 'bold'}}>{start}–{end}</span>
}

const DateFilter = ({ start, end, onChange }) => {
  const [allDateRanges, setAllDateRanges] = useState(null)
  const [selectedStart, setStart] = useState(start)
  const [selectedEnd, setEnd] = useState(end)
  const [minDate, setMinDate] = useState(0)
  const [maxDate, setMaxDate] = useState(0)
  const changer = typeof onChange === 'function' ? onChange : ()=>{}

  // sets selectedStart/End to the inputted dates
  const resetDates = () => {
    setStart(start)
    setEnd(end)
  }

  useEffect(() => {
    resetDates()
  }, [start, end])

  useEffect(() => {
    api.dateRanges()
      .then(ranges => {
        setAllDateRanges(ranges)
        setMinDate(ranges[0].start)
        setMaxDate(ranges[ranges.length-1].end)
      })
  }, [])

  if (!allDateRanges) {
    return 'Loading...'
  }

  const rangeChange = values => {
    setStart(values[0])
    setEnd(values[1])
  }

  const onApply = () => {
    changer({ start: selectedStart, end: selectedEnd })
  }

  const isStartNum = typeof selectedStart === 'number'
  const isEndNum = typeof selectedEnd === 'number'
  const filterMenu = (
    <div style={{padding: '1.5em'}}>
      <div style={{marginBottom: '1em'}}>
        <div style={{display: 'flex'}}>
          <div style={{marginRight: '.25em'}}>
            <EditableText
              type="number"
              value={selectedStart}
              placeholder="Start"
              style={{maxWidth: '60px', width: 20}}
              onChange={val => {
                const date = parseInt(val)
                if (!isNaN(date)) {
                  setStart(date)
                } else {
                  setStart(0)
                }
              }}
            />
          </div>
          <div style={{marginRight: '.25em'}}>
            <EditableText
              type="number"
              value={selectedEnd}
              placeholder="End"
              style={{maxWidth: '60px'}}
              onChange={val => {
                const date = parseInt(val)
                if (!isNaN(date)) {
                  setEnd(date)
                } else {
                  setEnd(0)
                }
              }}
            />
          </div>
        </div>
        <RangeSlider
          value={[isStartNum ? selectedStart : minDate, isEndNum ? selectedEnd : maxDate]}
          min={minDate}
          max={maxDate}
          stepSize={100}
          labelRenderer={false}
          onChange={rangeChange}
        />
      </div>
      <Button
        small
        style={{width: '100%'}}
        intent={Intent.PRIMARY}
        onClick={onApply}
      >
        Apply
      </Button>
    </div>
  )

  return (
    <FilterTag
      name="Date"
      filterSummary={dateFilterSummary({ start: selectedStart, end: selectedEnd })}
      filterMenu={filterMenu}
      onPopoverClose={resetDates}
      onRemove={() => {
        setStart(null)
        setEnd(null)
        changer({ start: null, end: null })
      }}
    />
  )
}

// TODO
// city filter
// encode identifier uris (St. Paul levan... has a '/' as part of its identifier)
// Tooltip highlight preview summary over filter bar pills (maybe)
// histogram of dates filter
// page for bib refs and shows what is referenced by them
const FilterBar = ({ filters, onChange, onClearFilters }) => {
  const changer = typeof onChange === 'function' ? onChange : ()=>{}
  const [searchOption, setSearchOption] = useState(searchValues[0])
  const [searchInput, setSearchInput] = useState('')
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)
  const [workIds, setWorkIds] = useState(null)
  const [libraries, setLibraries] = useState(null)
  const [cities, setCities] = useState(null)
  const [incipit, setIncipit] = useState(null)
  const [explicit, setExplicit] = useState(null)
  const [themes, setThemes] = useState(null)
  const debouncedSearchInput = useDebounce(searchInput, 250)

  const hasFilters = [
    dateStart,
    dateEnd,
    Array.isArray(workIds) && workIds.length > 0,
    searchInput,
    Array.isArray(libraries) && libraries.length > 0,
    Array.isArray(cities) && cities.length > 0,
    incipit,
    explicit,
    Array.isArray(themes) && themes.length > 0,
  ].reduce((acc, curr) => Boolean(curr) || acc, false)

  useEffect(() => {
    // parse out filters
    if (filters) {
      Object.keys(filters).map(k => {
        // parse codico
        if (k.startsWith('codicological.date_start')) {
          setDateStart(parseInt(filters[k]))
        }
        if (k.startsWith('codicological.date_end')) {
          setDateEnd(parseInt(filters[k]))
        }

        // parse works
        if (k.startsWith('work.')) {
          setWorkIds(filters[k])
        }

        // parse debounced search
        const parts = k.split('.')
        if (parts.length === 2 && parts[0] in searchValues && parts[1] == 'like') {
          setSearchInput(filters[k].trim('%'))
          return
        }

      })
    } else {
      setSearchInput('')
      setDateStart(null)
      setDateEnd(null)
      setWorkIds(null)
      setLibraries(null)
      setCities(null)
      setIncipit(null)
      setExplicit(null)
      setThemes(null)
    }
  }, [filters])

  useEffect(() => {
    let newFilters = null
    if (debouncedSearchInput) {
      newFilters = {
        [`${searchOption}.like`]: `%${debouncedSearchInput}%`,
      }
    }
    if (typeof dateStart === 'number') {
      newFilters = {
        ...newFilters,
        ['codicological_unit.date_start.gte']: dateStart
      }
    }
    if (typeof dateEnd === 'number') {
      newFilters = {
        ...newFilters,
        ['codicological_unit.date_end.lte']: dateEnd
      }
    }
    if (Array.isArray(workIds) && workIds.length > 0) {
      newFilters = {
        ...newFilters,
        ['work.in']: workIds, // this is a query on textual units
        // TODO, we may want to remove knowledge of what model the returned value will query from here
      }
    }
    if (Array.isArray(libraries) && libraries.length > 0) {
      newFilters = {
        ...newFilters,
        ['library.in']: libraries,
      }
    }
    if (Array.isArray(cities) && cities.length > 0) {
      newFilters = {
        ...newFilters,
        ['city.in']: cities,
      }
    }
    if (Array.isArray(themes) && themes.length > 0) {
      newFilters = {
        ...newFilters,
        ['thematic_tags.name.in']: themes,
      }
    }
    // this is a textual unit filter that was migrated from search input
    if (typeof incipit === 'string' && incipit.length > 0) {
      newFilters = {
        ...newFilters,
        ['incipit.like']: `%${incipit}%`
      }
    }

    // this is a textual unit filter that was migrated from search input
    if (typeof explicit === 'string' && explicit.length > 0) {
      newFilters = {
        ...newFilters,
        ['explicit.like']: `%${explicit}%`
      }
    }

    changer(newFilters)
  }, [debouncedSearchInput, dateStart, dateEnd, workIds, libraries, cities, incipit, explicit, themes])

  const handleSearch = e => {
    setSearchInput(e.target.value)
  }

  const handleDateFilterChange = ({ start, end }) => {
    setDateStart(start)
    setDateEnd(end)
  }

  const handleWorksFilterChange = ({ works }) => {
    setWorkIds(works.map(w => w.id))
  }

  const handleLibrariesFilterChange = ({ libraries }) => {
    setLibraries(libraries)
  }

  const handleCitiesFilterChange = ({ cities }) => {
    setCities(cities)
  }

  const handleIncipitChange = ({ incipit }) => {
    setIncipit(incipit)
  }

  const handleExplicitChange = ({ explicit }) => {
    setExplicit(explicit)
  }

  const handleThemesFilterChange = ({ themes }) => {
    setThemes(themes)
  }

  return (
    <div>
      <InputGroup
        value={searchInput}
        onChange={handleSearch}
        leftIcon="search"
        placeholder="Search"
        rightElement={<SearchOption value={searchOption} onChange={opt => setSearchOption(opt)} />}
        style={{backgroundColor: 'transparent', border: `1px solid ${Colors.BLACK}`, boxShadow: 'none'}}
      />
      <div style={{marginTop: '1em', display: 'flex', flexBasis: 'auto', alignItems: 'center', flexWrap: 'wrap'}}>
        <div style={{marginRight: '.5em', marginBottom: '.5em'}}>
          <DateFilter start={dateStart} end={dateEnd} onChange={handleDateFilterChange} />
        </div>
        <div style={{marginRight: '.5em', marginBottom: '.5em'}}>
          <WorkFilter works={workIds} onChange={handleWorksFilterChange} />
        </div>
        <div style={{marginRight: '.5em', marginBottom: '.5em'}}>
          <LibraryFilter libraries={libraries} onChange={handleLibrariesFilterChange} />
        </div>
        <div style={{marginRight: '.5em', marginBottom: '.5em'}}>
          <CityFilter cities={cities} onChange={handleCitiesFilterChange} />
        </div>
        <div style={{marginRight: '.5em', marginBottom: '.5em'}}>
          <IncipitFilter incipit={incipit} onChange={handleIncipitChange} />
        </div>
        <div style={{marginRight: '.5em', marginBottom: '.5em'}}>
          <ExplicitFilter explicit={explicit} onChange={handleExplicitChange} />
        </div>
        <div style={{marginRight: '.5em', marginBottom: '.5em'}}>
          <ThemeFilter themes={themes} onChange={handleThemesFilterChange} />
        </div>
        {hasFilters &&
          <div style={{marginRight: '.5em', marginBottom: '.5em', fontWeight: 'bold'}}>
            <small>
              <a style={{color: Colors.BLACK, whiteSpace: 'nowrap'}} onClick={onClearFilters}>
                <Icon icon="small-cross" />Clear filters
              </a>
            </small>
          </div>
        }
      </div>
    </div>
  )
}

const Contents = ({ contents }) => {
  if (!contents) {
    return <Noner none="No information is yet available on the contents of this manuscript" />
  }

  const parts = contents.split(';')
  const elems = parts.map(p => (
    <div key={p}>{p}</div>
  ))
  return (
    <div>
      {elems}
    </div>
  )
}

const OldSignatures = ({ oldSignatures }) => {
  if (Array.isArray(oldSignatures) && oldSignatures.length > 0) {
    const elems = oldSignatures.map(os => (
      <div key={os} style={{display: 'inline'}}>
        {os}
      </div>
    ))
    return (
      <div style={{fontWeight: 400, fontFamily: 'iAWriter'}}>
        <small style={{display: 'flex', alignItems: 'start'}}>
          <Icon
            icon="key-enter"
            style={{fontWeight: 200, transform: 'scale(-1,1)', marginRight: '.5em', color: Colors.BLACK}}
          />
          <span style={{display: 'flex'}}>
            <DataLabel style={{marginRight: '.75em'}}>
              Former shelf{stringUtils.plur(oldSignatures.length, 'mark', 'marks')}
            </DataLabel> {elems}
          </span>
        </small>
      </div>
    )
  }

  return null
}

export const CodicoCard = ({ codico }) => {
  const hasDateAuthority = Boolean(codico.date_authority)
  let material = null
  if (Array.isArray(codico.material) && codico.material.length > 0) {
    material = codico.material.map(m => <BoxTag key={m} style={{marginBottom: '.3em', marginRight: '.3em'}}>{m}</BoxTag>)
  }

  let dateAuthorityInfo = null
  if (hasDateAuthority) {
    dateAuthorityInfo = (
      <div>
        <div style={{marginBottom: '1em'}}><strong>Date authority</strong></div>
        <BibRefList bibrefIds={codico.date_authority} />
      </div>
    )
  }

  const dateInfo = (
    <div style={{display: 'flex'}}>
      <div>
        <span>{dateUtils.formatDateModifier(codico.date_modifier)}</span>
        <span> </span>
        <DateRange
          start={codico.date_start}
          end={codico.date_end}
          dateRange={codico.date_range}
        />
      </div>
      {hasDateAuthority &&
        <InfoTip
          contentStyle={{maxWidth: '450px', padding: '1em'}}
          content={dateAuthorityInfo}
          style={{marginLeft: '.5em'}}
        />
       }
    </div>
  )

  return (
    <Card
      style={{
        marginBottom: '1em',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: `1px solid ${Colors.BLACK}`,
        padding: '20px 0 0 0',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '.75fr .75fr 1fr 1.5fr .75fr',
          gridAutoFlow: 'column',
          paddingBottom: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <MicroInfo
          title="Support"
          titletip="Material"
          none="Unknown"
          info={material}
        />
        <MicroInfo
          title="Page size"
          none="Unknown"
          info={codico.page_size ? <BoxTag round>{codico.page_size}</BoxTag> : null}
        />
        <MicroInfo
          title="Dimensions"
          info={<Dimensions none="Unknown" width={codico.width} height={codico.height} /> }
        />

        <MicroInfo
          title="Date"
          none="Unknown"
          info={dateInfo}
        />

        <MicroInfo
          title="Folios"
          info={<FolioRange none="Unknown" start={codico.folioStart} end={codico.folioEnd} />}
        />
      </div>
      <div style={{padding: '10px 20px', borderTop: `1px solid ${Colors.BLACK}`}}>
        <Notes notes={codico.notes} style={{overflowWrap: 'break-word'}} />
      </div>
    </Card>
  )
}

const CodicologicalUnits = ({ codicos }) => {
  const elems = codicos.map(cu => {
    return (
      <CodicoCard key={cu.id} codico={cu} />
    )
  })
  return (
    <div>{elems}</div>
  )
}

// codicologicalUnits is an array of codicologicalUnits that contain this textualUnit and is used to determine and
// display a date range for this textual unit
export const TextualUnitCard = ({ manuscript, textualUnit, codicologicalUnits, showReferences=true }) => {
  const tu = textualUnit
  const [work, setWork] = useState(null)
  const [selectedTab, setSelectedTab] = useState(null)
  const hasNotes = typeof tu.notes === 'string' && tu.notes.length > 0
  const hasReferences = Array.isArray(tu.reference) && tu.reference.length > 0
  const hasCodicos = Array.isArray(codicologicalUnits) && codicologicalUnits.length > 0

  useEffect(() => {
    if (textualUnit.work) {
      if (typeof textualUnit.work === 'string') {
        // expand the work
        api.work(textualUnit.work).then(w => setWork(w))
      } else {
        // it's already expanded
        setWork(textualUnit.work)
      }
    }
  }, [textualUnit.work])

  let referenceInfo = null
  if (hasReferences) {
    referenceInfo = (
      <div>
        <div style={{marginBottom: '1em'}}><strong>Reference authority</strong></div>
        <div style={{marginBottom: '1em'}}>
          The following are authorities that have enabled identification of this textual unit in the manuscript:
        </div>
        <BibRefList bibrefIds={tu.reference} />
      </div>
    )
  }

  const now = new Date(Date.now())
  const citationInfo = (
    <div>
      {work?.author.name}, '{work?.title}' ({manuscript.signature}) in Rubin, Jonathan and Jose Maria Andres Porras, eds. Reading the Holy Land Database, {urls.site}{paths.manuscripts(manuscript.identifier)}.  Accessed {now.toLocaleDateString('en-GB')/* MM/DD/YYYY */}.
    </div>
  )

  // close tab if it's already open and user clicked the same tab title, else open the new tab
  const toggleTab = tab => setSelectedTab(prev => prev === tab ? null : tab)

  return (
    <Card
      key={tu.id}
      style={{
        marginBottom: '1em',
        padding: 0,
        backgroundColor: 'rgb(253, 253, 248)',
        boxShadow: 'none',
        border: `1px solid ${Colors.BLACK}`,
      }}
    >
      <div
        style={{padding: '0px 0px 0px 0px', visibility: (hasReferences && showReferences) ? 'visible' : 'hidden'}}
      >
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <InfoTip
            contentStyle={{maxWidth: '450px', padding: '1em', overflowWrap: 'anywhere'}}
            content={citationInfo}
            style={{borderTop: null, borderLeft: null, borderTopRightRadius: 0, borderBottomLeftRadius: 0}}
            popoverProps={{placement: 'right'}}
          >
            Cite
          </InfoTip>
          <InfoTip
            contentStyle={{maxWidth: '450px', padding: '1em'}}
            content={referenceInfo}
            style={{borderTop: null, borderRight: null, borderBottomRightRadius: 0, borderTopLeftRadius: 0}}
            popoverProps={{placement: 'right'}}
          />
        </div>
      </div>
      <div style={{marginBottom: '1em', padding: '0px 20px 0px 20px'}}>
        <div
          style={{
            marginBottom: '1em',
            display: 'grid',
            gridTemplateColumns: 'minmax(auto, 1.1fr) .2fr .35fr .45fr',
            columnGap: '.4em',
          }}
        >
          <MicroInfo
            title="Title"
            info={
              <div className={work ? null : Classes.SKELETON}>
                <Link to={work ? paths.works(work.identifier) : null} style={{textDecoration: 'none', color: Colors.BLACK}}>
                  {!work && <Noner none="No title" />}
                  {work && <span style={{fontWeight: 'bold'}}>{work.title}</span>}
                </Link>
              </div>
            }
          />

          <span>{/* for the spacing */}</span>

          <MicroInfo
            title="Date"
            style={{textAlign: 'right'}}
            info={
              <div className={work ? null : Classes.SKELETON}>
                <WorkDate work={work} />
              </div>
            }
          />

          <MicroInfo
            title="Author"
            info={
              <div className={work ? null : Classes.SKELETON}>
                <AuthorLink author={work?.author} />
              </div>
            }
            style={{textAlign: 'right'}}
          />

        </div>

      </div>
      <div
        style={{
          backgroundColor: 'rgb(250, 240, 216)',
          borderTop: `1px solid ${Colors.BLACK}`,
        }}
      >
        <div
          style={{
            marginBottom: '1em',
            display: 'grid',
            gridTemplateColumns: 'minmax(auto, 1.1fr) .2fr .35fr .45fr',
            columnGap: '.4em',
            padding: '20px',
          }}
        >
          <MicroInfo
            title="Rubric"
            titletip="Title of work in manuscript"
            info={tu.title ? <div>{tu.title}</div> : null}
          />

          <MicroInfo
            title="Excerpt"
            info={tu.excerpt ? 'Yes' : 'No'}
            style={{textAlign: 'right'}}
          />

          <MicroInfo
            title="Date of origin"
            style={{textAlign: 'right'}}
            info={
              <span>
                {hasCodicos && codicologicalUnits.map(c =>
                  <DateRange
                    key={c.id}
                    start={c.date_start}
                    end={c.date_end}
                    dateRange={c.date_range}
                  />
                )}
                {!hasCodicos && <Noner none="Unknown" />}
              </span>
            }
          />

          <MicroInfo
            title="Span of folios"
            info={
              <div>
                <FolioRange start={tu.folioStart} end={tu.folioEnd} none="Unknown" />
              </div>
            }
            style={{textAlign: 'right'}}
          />

        </div>
        <div
          style={{
            display: 'flex',
            fontSize: '12px',
            borderTop: `1px solid ${Colors.BLACK}`,
            borderBottom: Boolean(selectedTab) ? `1px solid ${Colors.BLACK}` : null,
          }}
        >
          {Boolean(tu.prologue) &&
            <HoverCardPill
              hoverable={Boolean(tu.prologue)}
              onClick={() => toggleTab('incipit-prologue')}
              highlight={selectedTab === 'incipit-prologue'}
            >
              Incipit prologue
            </HoverCardPill>
          }
          <HoverCardPill
            hoverable={Boolean(tu.incipit)}
            onClick={() => toggleTab('incipit')}
            highlight={selectedTab === 'incipit'}
          >
            <Noner none="Incipit">{tu.incipit ? 'Incipit' : null}</Noner>
          </HoverCardPill>
          <HoverCardPill
            hoverable={Boolean(tu.explicit)}
            onClick={() => toggleTab('explicit')}
            highlight={selectedTab === 'explicit'}
          >
            <Noner none="Explicit">{tu.explicit ? 'Explicit' : null}</Noner>
          </HoverCardPill>
          {Boolean(tu.colophon) &&
            <HoverCardPill
              hoverable={Boolean(tu.colophon)}
              onClick={() => toggleTab('colophon')}
              highlight={selectedTab === 'colophon'}
            >
              Colophon
            </HoverCardPill>
          }
          {Boolean(tu.evidence_of_readers) &&
            <HoverCardPill
              hoverable={Boolean(tu.evidence_of_readers)}
              onClick={() => toggleTab('evidence-of-readers')}
              highlight={selectedTab === 'evidence-of-readers'}
            >
              Evidence of readers
            </HoverCardPill>
          }
          {Boolean(tu.notes) &&
            <HoverCardPill
              hoverable={Boolean(tu.notes)}
              onClick={() => toggleTab('notes')}
              highlight={selectedTab === 'notes'}
            >
              Notes
            </HoverCardPill>
          }
        </div>
        <Collapse isOpen={Boolean(selectedTab)}>
          <div>
            <MicroInfo
              style={{padding: '20px 20px 14px 20px'}}
              className={selectedTab === 'incipit-prologue' ? 'highlighted-content' : null}
              title="Incipit prologue"
              info={tu.prologue}
            />
            <MicroInfo
              style={{padding: '10px 20px 14px 20px'}}
              className={selectedTab === 'incipit' ? 'highlighted-content' : null}
              title="Incipit"
              info={tu.incipit}
            />
            <MicroInfo
              style={{padding: '10px 20px 14px 20px'}}
              className={selectedTab === 'explicit' ? 'highlighted-content': null}
              title="Explicit"
              info={tu.explicit}
            />
            <MicroInfo
              style={{padding: '10px 20px 14px 20px'}}
              className={selectedTab === 'colophon' ? 'highlighted-content': null}
              title="Colophon"
              info={tu.colophon}
            />
            <MicroInfo
              style={{padding: '10px 20px 14px 20px'}}
              className={selectedTab === 'evidence-of-readers' ? 'highlighted-content' : null}
              title="Evidence of readers"
              info={tu.evidence_of_readers}
            />
            <MicroInfo
              style={{padding: '10px 20px 24px 20px'}}
              className={selectedTab === 'notes' ? 'highlighted-content' : null}
              title="Notes"
              info={<Notes notes={tu.notes} />}
            />
          </div>
        </Collapse>
      </div>
    </Card>
  )
}

export const Manuscript = () => {
  const [ms, setMs] = useState(null)
  const [textualUnits, setTextualUnits] = useState(null)
  const [codicologicalUnits, setCodicologicalUnits] = useState(null)
  const [allWorks, setAllWorks] = useState([])
  const { mssig } = useParams()

  useTitle(ms ? ms.signature : 'Manuscript')

  useEffect(() => {
    if (mssig) {
      if (idUtils.isId(mssig)) {
        api.manuscript(mssig).then(m => setMs(m))
        api.textualUnits({ query: { manuscript: mssig } })
          .then(tus => setTextualUnits(tus))
        api.codicologicalUnits({ query: { manuscript: mssig } })
          .then(cus => setCodicologicalUnits(cus))
      } else {
        api.manuscripts({ query: { identifier: mssig }})
          .then(ms => {
            if (!Array.isArray(ms) || ms.length !== 1) {
              // throw 404
              // TODO add error handling here
              return
            }
            const m = ms[0]
            // TODO fix this double query. we shouldn't need to do it, but some things are expanded on the id query
            // that are not expanded on the general query
            api.manuscript(m.id).then(m => setMs(m))
            api.textualUnits({ query: { manuscript: m.id } })
              .then(tus => setTextualUnits(tus))
            api.codicologicalUnits({ query: { manuscript: m.id } })
              .then(cus => setCodicologicalUnits(cus))
          })
      }
    }
  }, [mssig])

  useEffect(() => {
    // get all works for the data vis
    api.works()
      .then(works => {
        const wm = works.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {})
        setAllWorks(wm)
      })
  }, [])

  const msCrumbs = [
    { href: paths.index(), text: 'Reading the Holy Land'},
    { href: paths.manuscripts(), text: 'Manuscripts'},
    { text: mssig },
  ]

  if (!ms) {
    return <Spinner size={SpinnerSize.SMALL} />
  }

  let textUnitElems = <Spinner size={SpinnerSize.SMALL} />
  if (textualUnits && codicologicalUnits && allWorks) {
    textUnitElems = textualUnits.map(tu => {
      const codicos = folioUtils.getContainingRanges({
        ranges: codicologicalUnits,
        folioStart: tu.folioStart,
        folioEnd: tu.folioEnd,
        startExtractor: cu => cu.folioStart,
        endExtractor: cu => cu.folioEnd,
      })
      return (
        <TextualUnitCard key={tu.id} manuscript={ms} textualUnit={tu} codicologicalUnits={codicos} />
      )
    })
  }

  let questionable = null
  if (ms.place_of_origin_questionable) {
    questionable = (
      <InfoTip
        content="The place of origin proposed here is uncertain..."
        containerStyle={{display: 'inline-block'}}
        popoverProps={{placement: 'bottom'}}
      >
        ?
      </InfoTip>
    )
  }

  let placeOfOriginNotes = null
  if (ms.place_of_origin_notes) {
    placeOfOriginNotes = (
      <InfoTip
        content={<Notes notes={ms.place_of_origin_notes} />}
        contentStyle={{maxWidth: '400px'}}
        popoverProps={{placement: 'bottom'}}
        containerStyle={{display: 'inline-block'}}
      />
    )
  }

  const placeOfOrigin = (
    <div style={{display: 'flex'}}>
      <div>
        <Noner none="Unknown">{ms.place_of_origin}</Noner> {questionable} {placeOfOriginNotes}
      </div>
    </div>
  )

  return (
    <div>
      <Crumbline items={msCrumbs} />
      <Title style={{marginBottom: '14px'}}>{ms.signature} <OldSignatures oldSignatures={ms.old_signature} /></Title>

      <div
        style={{
          display: 'flex',
          marginBottom: '1.5em',
          borderRadius: '2px',
          borderTop: `4px solid ${Colors.BLACK}`,
          //borderBottom: `1px solid ${Colors.BLACK}`,
          //borderLeft: `1px solid ${Colors.BLACK}`,
          //borderRight: `1px solid ${Colors.BLACK}`,
        }}
      >
        <MicroInfo
          title="Identifier"
          info={ms.identifier}
          style={{marginLeft: '8px', marginTop: '4px'}}
        />
        <Divider />
        <MicroInfo style={{marginTop: '4px'}} title="City" info={ms.city} />
        <Divider />
        <MicroInfo style={{marginTop: '4px'}} title="Library" info={ms.library} />
        <Divider />
        <MicroInfo
          style={{marginTop: '4px'}}
          title="Place of origin"
          info={placeOfOrigin}
          none="Unknown"
        />
        <Divider />
        <MicroInfo
          style={{marginTop: '4px'}}
          title="Leaves in codex"
          info={
            <FolioInfo
              folios={ms.folios}
              frontFlyLeaves={ms.front_fly_leaves}
              backFlyLeaves={ms.back_fly_leaves}
            />
          }
        />
        <Divider />
        <MicroInfo style={{marginTop: '4px'}} title="Links" info={<ScanButton scanLinks={ms.scan_link} />} />
      </div>
      <MediumInfo
        title="Manuscript contents"
        info={
          <>
            {ms.contents && <Folios manuscript={ms} textualUnits={textualUnits} allWorks={allWorks} />}
            <Contents contents={ms.contents} />
          </>
        }
      />
      <MediumInfo title="Notes" info={<Notes notes={ms.notes} />} />
      <MediumInfo
        title="Codicological units"
        info={codicologicalUnits ? <CodicologicalUnits codicos={codicologicalUnits} /> : 'Loading...'}
      />
      <MediumInfo
        title="Textual units"
        info={textUnitElems}
        tooltip="Here are listed textual units that are part of our project's corpus"
      />
      <MediumInfo
        title="Provenance"
        info={<Notes none="No information available" notes={ms.provenance} />}
      />
      <MediumInfo
        title="Catalogues"
        info={
          <BibRefList
            bibrefIds={Array.isArray(ms.catalogue) ? ms.catalogue.map(c => c.id) : null}
          />
        }
      />
    </div>
  )
}
