const index = () => {
  return '/'
}

const works = path => {
  return `/works${path ? `/${path}` : ''}`
}

const authors = path => {
  return `/authors${path ? `/${path}` : ''}`
}

const manuscripts = path => {
  return `/manuscripts${path ? `/${path}` : ''}`
}

const bibliographies = path => {
  return `/bibliography${path ? `/${path}` : ''}`
}

const catalogues = path => {
  return `/catalogues${path ? `/${path}` : ''}`
}

const search = path => {
  return `/search${path ? `/${path}` : ''}`
}

const citation = path => {
  return `/citation`
}

const about = path => {
  return `/about`
}

export default {
  index,
  works,
  authors,
  manuscripts,
  bibliographies,
  catalogues,
  search,
  citation,
  about,
}
