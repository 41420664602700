// m_UgRooPP5tGnvq8FswJvDbb
const isModel = id => {
  if (typeof id === 'string') {
    const parts = id.split('_')
    return parts.length === 2 && parts[0] === 'm'
  }
  return false
}

// e-m_LwxiQyrGj66Hf8DQgTYfyK
const isEntry = id => {
  if (typeof id === 'string') {
    const parts = id.split('_')
    if (parts.length === 2) {
      const subparts = parts[0].split('-')
      return subparts.length === 2 && subparts[0] === 'e'
    }
  }
  return false
}

// f_nAB9x7q8v4W4mKoo2D5T5c
const isField = id => {
  if (typeof id === 'string') {
    const parts = id.split('_')
    return parts.length === 2 && parts[0] === 'f'
  }
  return false
}

// enum_2FkhrEdzD53m3t5Nbn99nE
const isEnum = id => {
  if (typeof id === 'string') {
    const parts = id.split('_')
    return parts.length === 2 && parts[0] === 'enum'
  }
  return false
}


// Truncates an id and returns the type info plus
// the first four characters of the uuid, e.g.
// enum_2FkhrEdzD53m3t5Nbn99nE => enum_2Fkh
// e-m_LwxiQyrGj66Hf8DQgTYfyK => e-m_Lwxi
// if invalid, returns null
const truncate = id => {
  if (typeof id === 'string') {
    const parts = id.split('_')
    if (parts.length === 2) {
      return `${parts[0]}_${parts[1].substring(0,4)}`
    }
  }
  return null
}

// this should check all id functions to determine if
// given string is an id
const isId = str =>
  [isModel, isEntry, isField, isEnum]
    .reduce((acc, curr) => acc || curr(str), false)

export default {
  isId,
  isModel,
  isField,
  isEntry,
  isEnum,
  truncate,
}
