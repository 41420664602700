import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Spinner, SpinnerSize } from '@blueprintjs/core'
import { NotFound } from './404'
import paths from '../utils/paths'
import * as api from '../api'

export const Search = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const identifier = id.toLowerCase().trim()
  const [identifiers, setIdentifiers] = useState({}) // all identifiers
  const [manuscriptsLoaded, setManuscriptsLoaded] = useState(false)
  const [worksLoaded, setWorksLoaded] = useState(false)
  const [authorsLoaded, setAuthorsLoaded] = useState(false)
  const [bibsLoaded, setBibsLoaded] = useState(false)
  const [notFound, setNotFound] = useState(false)

  // try and search for identifiers
  useEffect(() => {

    api.manuscripts({}, { sort: false })
      .then(mss => {
        const allms = mss.reduce((acc, curr) => {
          acc[curr.identifier.toLowerCase().trim()] = { ...curr, type: 'manuscript' }
          return acc
        }, {})
        setIdentifiers(prev => ({ ...prev, ...allms }))
        setManuscriptsLoaded(true)
      })

    api.works({}, { sort: false })
      .then(works => {
        const allwks = works.reduce((acc, curr) => {
          acc[curr.identifier.toLowerCase().trim()] = { ...curr, type: 'work' }
          return acc
        }, {})
        setIdentifiers(prev => ({ ...prev, ...allwks }))
        setWorksLoaded(true)
      })

    api.authors({}, { sort: false })
      .then(authors => {
        const allauts = authors.reduce((acc, curr) => {
          acc[curr.identifier.toLowerCase().trim()] = { ...curr, type: 'author' }
          return acc
        }, {})
        setIdentifiers(prev => ({ ...prev, ...allauts }))
        setAuthorsLoaded(true)
      })

    api.bibliographies({}, { sort: false })
      .then(bibs => {
        const allbibs = bibs.reduce((acc, curr) => {
          acc[curr.identifier.toLowerCase().trim()] = { ...curr, type: 'bib' }
          return acc
        }, {})
        setIdentifiers(prev => ({ ...prev, ...allbibs }))
        setBibsLoaded(true)
      })
  }, [])

  useEffect(() => {
    if (manuscriptsLoaded && worksLoaded && authorsLoaded && bibsLoaded) {
      if (identifiers.hasOwnProperty(identifier)) {
        const ident = identifiers[identifier]
        switch(ident.type) {
          case 'manuscript':
            navigate(paths.manuscripts(ident.identifier), { replace: true })
            break
          case 'work':
            navigate(paths.works(ident.identifier), { replace: true })
            break
          case 'author':
            navigate(paths.authors(ident.identifier), { replace: true })
            break
          case 'bib':
            let fn = paths.bibliographies
            if (ident.is_catalogue) {
              fn = paths.catalogues
            }
            navigate(fn(ident.identifier), { replace: true })
            break
        }
      }
      setNotFound(true)
    }
  }, [manuscriptsLoaded, worksLoaded, authorsLoaded, bibsLoaded])

  if (notFound) {
    return <NotFound />
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10em'}}>
      <Spinner size={SpinnerSize.SMALL} />
    </div>
  )
}
