const formatDateModifier = modifier => {
  switch(modifier) {
    case 'circa':
      return 'ca.'
    case 'after':
      return 'after'
    case 'before':
      return 'before'
    default:
      return ''
  }
}

export default {
  formatDateModifier,
}
