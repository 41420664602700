export const server = process.env.REACT_APP_API_URL || 'http://localhost:8000'

export const req = async ({ method='GET', url, body }) => {
  const opts = {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }
  const res = await fetch(`${server}${url}`, opts)
  const text = await res.text()
  return text ? JSON.parse(text) : null
}

const encode = (k, v) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
// query is an object with key value. will be encoded to a url query param
export const packQuery = query => {
  if (query) {
    const queries = Object.keys(query).reduce((acc, curr) => {
      const next = [...acc]
      if (Array.isArray(query[curr])) {
        next.push(...query[curr].map(v => encode(curr, v)))
      } else {
        next.push(encode(curr, query[curr]))
      }
      return next
    }, [])
    return queries.join('&')
  }
  return ''
}
