export const RECTO = 'r'
export const VERSO = 'v'
const validSides = [RECTO, VERSO]

const A = 'a'
const B = 'b'
const C = 'c'
const D = 'd'
const E = 'e'
const F = 'f'
const G = 'g'
const validColumns = [A, B, C, D, E, F, G]

export class FolioRange {
  constructor(folioStart, folioEnd) {
    if ((!folioStart.isEmpty() && !folioEnd.isEmpty()) && folioEnd < folioStart) {
      throw new Error('Invalid folio range')
    }
    this.start = folioStart
    this.end = folioEnd
  }

  toString() {
    return `${this.start.toString()}–${this.end.toString()}`
  }

  // returns the folios numbers contained within this folio range
  enumerateFolioNumbers() {
    if (this.start.isEmpty() && this.end.isEmpty()) {
      return []
    }
    if (this.start.isEmpty()) {
      return [this.end.number]
    }
    if (this.end.isEmpty()) {
      return [this.start.number]
    }
    let curr = this.start.number
    const nums = []
    while (curr <= this.end.number) {
      nums.push(curr)
      curr++
    }
    return nums
  }
}

const folioRangeRegex = /^(?<rangeStartNum>[0-9]+)(?<rangeStartSide>r|v)?(?<rangeStartCol>a|b|c|d|e|f|g)?(\-|\u2013|\u2014)(?<rangeEndNum>[0-9]+)(?<rangeEndSide>r|v)?(?<rangeEndCol>a|b|c|d|e|f|g)?$|^(?<singleNum>[0-9]+)(?<singleSide>r|v)?(?<singleCol>a|b|c|d|e|f|g)?$|^(?<unknownRangeStartNum>[0-9]+)(?<unknownRangeStartSide>r|v)?(?<unknownRangeStartCol>a|b|c|d|e|f|g)?(\-|\u2013|\u2014)$|^(\-|\u2013|\u2014)(?<unknownRangeEndNum>[0-9]+)(?<unknownRangeEndSide>r|v)?(?<unknownRangeEndCol>a|b|c|d|e|f|g)?$/

const validateFolio = (num, side, col) => {
  const sn = parseInt(num)
  if (Number.isNaN(num)) {
    return false
  }
  if (side && !validSides.includes(side)) {
    return false
  }
  if (col && !validColumns.includes(col)) {
    return false
  }
  return true
}

const get = (val, def) => {
  if (val === null || val === undefined || val === '') {
    return def
  }
  return val
}

export const parseFolioRange = input => {
  if (typeof input !== 'string' || input.length === 0) {
    return null
  }
  const match = input.match(folioRangeRegex)
  if (!match) {
    return null
  }
  const { groups } = match
  if (typeof groups.rangeStartNum === 'string') {
    const { rangeStartNum, rangeStartSide, rangeStartCol, rangeEndNum, rangeEndSide, rangeEndCol } = groups
    if (!validateFolio(rangeStartNum, rangeStartSide, rangeStartCol)) {
      return null
    }
    if (!validateFolio(rangeEndNum, rangeEndSide, rangeEndCol)) {
      return null
    }
    return new FolioRange(
      new Folio(parseInt(rangeStartNum), get(rangeStartSide, RECTO), rangeStartCol),
      new Folio(parseInt(rangeEndNum), get(rangeEndSide, VERSO), rangeEndCol),
    )
  }
  if (typeof groups.singleNum === 'string') {
    const { singleNum, singleSide, singleSideCol } = groups
    if (!validateFolio(singleNum, singleSide, singleSideCol)) {
      return null
    }
    return new FolioRange(
      new Folio(parseInt(singleNum), get(singleSide, RECTO), singleSideCol),
      new Folio(parseInt(singleNum), get(singleSide, VERSO), singleSideCol),
    )
  }
  if (typeof groups.unknownRangeStartNum === 'string') {
    const { unknownRangeStartNum, unknownRangeStartSide, unknownRangeStartCol } = groups
    if (!validateFolio(unknownRangeStartNum, unknownRangeStartSide, unknownRangeStartCol)) {
      return null
    }
    return new FolioRange(
      new Folio(parseInt(unknownRangeStartNum), get(unknownRangeStartSide, RECTO), unknownRangeStartCol),
      new Folio(0),
    )
  }
  if (typeof groups.unknownRangeEndNum === 'string') {
    const { unknownRangeEndNum, unknownRangeEndSide, unknownRangeEndCol } = groups
    if (!validateFolio(unknownRangeEndNum, unknownRangeEndSide, unknownRangeEndCol)) {
      return null
    }
    return new FolioRange(
      new Folio(0),
      new Folio(parseInt(unknownRangeEndNum), get(unknownRangeEndSide, VERSO), unknownRangeEndCol),
    )
  }
}

const folioRegex = /^(?<number>[0-9]+)(?<side>r|v)?$/
const parseFolio = input => {
  if (!input) {
    return null
  }
  const match = input.match(folioRegex)
  const num = parseInt(match.groups.number)
  if (Number.isNaN(num)) {
    return null
  }
  if (!validSides.includes(match.groups.side)) {
    return null
  }
  return {
    number: num,
    side: match.groups.side,
  }
}

export class Folio {
  constructor(number, side, column) {
    this.number = number
    this.side = side
    this.column = column
  }

  // parse only supports parsing folio number and side, not column
  parse(input) {
    const folio = parseFolio(input)
    if (!folio) {
      console.log(`Could not parse folio: ${input}`)
      return null
    }
    return new Folio(folio.number, folio.side)
  }

  valueOf() {
    // since pages are whole numbers, we can represent the recto/verso as decimals
    // decimals are used for comparison
    let decimal = this.side === RECTO ? 0 : 0.5
    const colidx = validColumns.indexOf(this.column)
    if (this.column && colidx > -1) {
      decimal = decimal + (colidx * 0.01)
    }
    return this.number + decimal
  }

  toString() {
    if (this.number === 0) {
      return '?'
    }
    return `${this.number}${this.side}${this.column ? this.column : ''}`
  }

  isEmpty() {
    return this.number === 0
  }

  //// inclusive of both start and end
  //// start and end are both folios as well
  //isBetween({ start, end }) {
  //}

  //isLTE(other) {
  //  if (this.number < other.number) {
  //    return true
  //  }
  //  if (this.number === other.number) {
  //    const sides = [this.side, other.side]
  //    if (sides[0] === sides[1] || (sides[0] === RECTO && sides[1] === VERSO)) {
  //    }
  //  }
  //  return false
  //}

  //isGTE(other) {
  //}
}


const isInRange = ({ folio, rangeStart, rangeEnd }) => {
  return folio >= rangeStart && folio <= rangeEnd
}

// given a list of ranges that have a start and end (determined via startExtractor and endExtractor),
// returns a list of ranges that contain the folio start/end, even if they are partially contained
const getContainingRanges = ({ ranges, folioStart, folioEnd, startExtractor, endExtractor }) => {
  if (!Array.isArray(ranges) || ranges.length === 0) {
    return null
  }
  const containingRanges = ranges.map(r => {
    const rangeStart = startExtractor(r)
    const rangeEnd = endExtractor(r)
    if (!rangeStart || !rangeEnd) {
      return null
    }
    const startInRange = isInRange({ folio: folioStart, rangeStart, rangeEnd })
    const endInRange = isInRange({ folio: folioEnd, rangeStart, rangeEnd })
    if (startInRange || endInRange) {
      return r
    }
    return null
  }).filter(Boolean)

  return containingRanges
}

export default {
  RECTO,
  VERSO,
  Folio,
  FolioRange,
  getContainingRanges,
  parseFolioRange,
}
