import { req, packQuery } from './util'
import { Folio } from '../utils/folio'
import { startEndSorter } from '../utils/sort'

const queryEnumInfo = ({ name }) => {
  return req({
    url: `/api/enums/${name}/spec`
  })
}

const queryEntries = ({ model, query }) => {
  return req({
    url: `/api/models/${model}/entries?${packQuery(query)}`
  })
}

const entryById = ({ model, id }) => {
  return req({
    url: `/api/models/${model}/entries/${id}`
  })
}

export const manuscripts = async ({ query }={}, { sort=true }={}) => {
  const ms = await queryEntries({ model: 'manuscript', query })
  return sort ? ms.sort((a, b) => a.signature.localeCompare(b.signature)) : ms
}

export const manuscript = id =>
  entryById({ model: 'manuscript', id })

export const works = async ({ query }={}, { sort=true }={}) => {
  const ws = await queryEntries({ model: 'work', query })
  return sort ? ws.sort((a, b) => a.title.localeCompare(b.title)) : ws
}
export const work = id =>
  entryById({ model: 'work', id })

export const textualUnits = async ({ query } = {}) => {
  const tus = await queryEntries({ model: 'textual_unit', query })
  return tus.map(t => ({
    ...t,
    folioStart: new Folio(t.folio_start_number, t.folio_start_side, t.folio_start_column),
    folioEnd: new Folio(t.folio_end_number, t.folio_end_side, t.folio_end_column),
  }))
  .sort((a, b) => startEndSorter(
    { start: a.folioStart, end: a.folioEnd },
    { start: b.folioStart, end: b.folioEnd },
  ))
}
export const textualUnit = id =>
  entryById({ model: 'textual_unit', id })

export const codicologicalUnits = async ({ query } = {}) => {
  const cus = await queryEntries({ model: 'codicological_unit', query })
  return cus.map(c => ({
    ...c,
    folioStart: new Folio(c.folio_start_number, c.folio_start_side),
    folioEnd: new Folio(c.folio_end_number, c.folio_end_side),
  }))
  .sort((a, b) => startEndSorter(
    { start: a.folioStart, end: a.folioEnd },
    { start: b.folioStart, end: b.folioEnd },
  ))
}

export const bibRef = ({ query }={}) =>
  queryEntries({ model: 'bibliographic_reference', query })

export const bibliographies = async ({ query } = {}, { sort=true }={}) => {
  const bibs = await queryEntries({ model: 'bibliography', query })
  return sort ? bibs.sort((a, b) => a.name.localeCompare(b.name)) : bibs
}

export const bibliography = id =>
  entryById({ model: 'bibliography', id })

export const authors = async ({ query } = {}, { sort=true }={}) => {
  const auts = await queryEntries({ model: 'author', query })
  return sort ? auts.sort((a, b) => a.name.localeCompare(b.name)) : auts
}
export const author = id =>
  entryById({ model: 'author', id })

export const allDateRanges = async () => {
  const drs = await queryEntries({ model: 'date_range' })
  return drs.sort((a, b) => a.start - b.start)
}

// returns all date ranges with exact match of start/end
export const dateRanges = async ({ query }={}) => {
  const drs = await queryEntries({ model: 'date_range', query })
  return drs.sort((a, b) => a.start - b.start)
}

export const exactDateRanges = ({ start, end }={}) => {
  let query = {}
  if (start) {
    query = { ...query, start }
  }
  if (end) {
    query = { ...query, end }
  }
  return dateRanges({ query })
}

export const dateRange = id =>
  entryById({ model: 'date_range', id })

export const libraries = async () => {
  const ls = await queryEnumInfo({ name: 'library' })
  return ls.enums.map(e => e.name).sort((a, b) => a.localeCompare(b))
}

export const cities = async () => {
  const cs = await queryEnumInfo({ name: 'city' })
  return cs.enums.map(e => e.name).sort((a, b) => a.localeCompare(b))
}

export const thematicTags = async () => {
  const tts = await queryEnumInfo({ name: 'thematic_tag' })
  return tts.enums.map(e => e.name).sort((a, b) => a.localeCompare(b))
}

// returns an array of all identifiers across all relevant models in the system
// identifiers are assumed unique across all models
export const allIdentifiers = async () => {
  const idents = await Promise.all([
    manuscripts({}, { sort: false })
      .then(mss => mss.map(ms => ms.identifier)),
    works({}, { sort: false })
      .then(works => works.map(wk => wk.identifier)),
    authors({}, { sort: false })
      .then(authors => authors.map(aut => aut.identifier)),
    bibliographies({}, { sort: false })
      .then(bibs => bibs.map(bib => bib.identifier)),
  ])
  return idents.flat()
}
