// sorts two values by start and end
// expects both a and b to be of form { start, end }
// where start and end are both comparable
export const startEndSorter = (a, b) => {
  if (a.start < b.start) {
    return -1
  } else if (a.start > b.start) {
    return 1
  }

  // starts are equal
  if (a.end < b.end) {
    return -1
  } else if (a.end > b.end) {
    return 1
  }

  return 0
}

// startFn and endFn are required.
// startFn/endFn returns the start/end date when passed an object for sort
export const makeDateSorter = ({ startFn, endFn }) => (a, b) => {

  // sort unknown dates last
  if (!Boolean(a)) {
    return 1
  }
  if (!Boolean(b)) {
    return -1
  }

  const startA = startFn(a)
  const endA = endFn(a)
  const startB = startFn(b)
  const endB = endFn(b)

  // sort unknown dates last
  if (!startA || !endA) {
    return 1
  }

  if (!startB || !endB) {
    return -1
  }


  if (startA === startB) {
    // if end date is equal, sort is unstable
    if (endA === endB) {
      return 0
    }
    return endA <= endB ? -1 : 1
  }
  return startA <= startB ? -1 : 1
}

export default {
  startEndSorter,
  makeDateSorter,
}
