import React from 'react'

const suffix = ' | RTHL'
const useTitle = title => {
  const documentDefined = typeof document !== 'undefined'
  const originalTitle = React.useRef(documentDefined ? document.title : null)

  React.useEffect(() => {
    if (!documentDefined) {
      return
    }

    if (title && document.title !== title) {
      document.title = `${title}${suffix}`
    }

    return () => {
      document.title = originalTitle.current
    }
  }, [title])
}

export default useTitle
