import { Spinner, SpinnerSize } from '@blueprintjs/core'
import { useEffect, useState, createContext } from 'react'
import { allIdentifiers } from '../api'

export const IdentifierContext = createContext()

const IdentifierProvider = ({ children }) => {
  const [identifiers, setIdentifiers] = useState(null)

  useEffect(() => {
    const load = async () => {
      const idents = await allIdentifiers()
      setIdentifiers(idents)
    }
    load()
  }, [])

  if (!identifiers) {
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
        <Spinner size={SpinnerSize.SMALL} />
      </div>
    )
  }

  return (
    <IdentifierContext.Provider value={{ identifiers }}>
      {children}
    </IdentifierContext.Provider>
  )
}

export default IdentifierProvider
