import useTitle from '../utils/title'
import { Colors } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import paths from '../utils/paths'
import { Title, Crumbline } from '../components'

const crumbs = [
  { href: paths.index(), text: 'Reading the Holy Land'},
  { text: 'Citation'},
]

export const Citation = () => {
  useTitle('Citation Guidelines')
  return (
    <div>
      <Crumbline items={crumbs} showCitation={false} />
      <Title>Citation Guidelines</Title>
      <div>
        <p>
          You are welcome to use this database for the purposes of teaching and research. If the database proves useful to you, please acknowledge it in any resulting publications in order to spread awareness of it among other scholars.
        </p>
        <p>
          Here is a sample citation for referencing the database as a whole:
        </p>
        <p style={{paddingLeft: '3em'}}>
          Rubin, Jonathan and Jose Maria Andres Porras, eds. Reading the Holy Land Database, https://data.readingtheholyland.org/. Accessed DD/MM/YYYY.
        </p>
        <p>
          When referencing individual textual units that have been located in manuscripts via the searchable database, users should first check the ‘Reference authority’ and the catalogues at the bottom of the manuscript page. For full bibliographic references, consult the <Link to={paths.bibliographies()} style={{textDecoration: 'none', fontWeight: 'bold', color: Colors.BLACK}}>Bibliography</Link> and <Link to={paths.catalogues()} style={{textDecoration: 'none', fontWeight: 'bold', color: Colors.BLACK}}>Catalogue</Link> pages of the database. Published citations should be made to these originating sources, insofar as this is possible, accompanied by a general acknowledgment of the Reading the Holy Land database.
        </p>

        <p>
          Should you wish to cite one of the textual units directly (whether because the originating source is inaccessible, or because the text was identified for the first time by our project), you are encouraged to cite it using the author (if applicable) and title of the work, and then the manuscript shelf mark in parentheses. The link to the entry for that manuscript in the database will include the unique identifier of that manuscript in the dataset.
        </p>

        <p>
          Here is a sample citation for referencing a textual unit:
        </p>
        <p style={{paddingLeft: '3em'}}>
          Burchard of Mount Sion, ‘Descriptio Terrae Sanctae’ (Berlin, Staatsbibliothek MS Lat. fol. 464) in Rubin, Jonathan and Jose Maria Andres Porras, eds. Reading the Holy Land Database, https://data.readingtheholyland.org/manuscripts/Berlin_SBPK_Lat_fol_464.  Accessed DD/MM/YYYY.
        </p>
        <p>
          If you have any questions, please feel free to reach out to the project leader, Jonathan Rubin (jonathan.rubin5775@gmail.com).
        </p>
      </div>
    </div>
  )
}
