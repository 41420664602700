import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Manuscript, Manuscripts } from './pages/manuscripts'
import { Work, Works } from './pages/works'
import { Author, Authors } from './pages/authors'
import { Catalogues, Bibs, Bib } from './pages/bibs'
import { Search } from './pages/search'
import { Citation } from './pages/citation'
import { About } from './pages/about'
import { ScrollToTop } from './components/scroll'
import Footer from './components/footer'
import Index from './pages'

function App() {
  return (
    <div style={{padding: '1em'}}>
      <div style={{width: '100%', backgroundColor: 'rgb(250, 240, 216)'}}>
        <div style={{maxWidth: '1024px', margin: 'auto', minHeight: '100vh', backgroundColor: 'rgb(250, 240, 216)'}}>
          <Router>
            {/* ScrollToTop resets page scroll to top when following a link. This overrides and disables the ability to scroll to a specific element when clicking on a link. */}
            <ScrollToTop />
            <Routes>
              <Route exact path="/manuscripts/:mssig" element={<Manuscript />} />
              <Route exact path="/manuscripts" element={<Manuscripts />} />
              <Route exact path="/works/:wsig" element={<Work />} />
              <Route exact path="/works" element={<Works />} />
              <Route exact path="/authors/:asig" element={<Author />} />
              <Route exact path="/authors" element={<Authors />} />
              <Route exact path="/bibliography/:bsig" element={<Bib />} />
              <Route exact path="/bibliography" element={<Bibs />} />
              <Route exact path="/catalogues/:bsig" element={<Bib isCatalogue />} />
              <Route exact path="/catalogues" element={<Catalogues />} />
              <Route exact path="/search/:id" element={<Search />} />
              <Route exact path="/citation" element={<Citation />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/" element={<Index />} />
            </Routes>
          </Router>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
