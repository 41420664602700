import { Spinner, SpinnerSize } from '@blueprintjs/core'
import { useEffect, useState, createContext } from 'react'
import { allDateRanges } from '../api'

export const DateRangeContext = createContext()

// Provides a map of date range id to date range object
const DateRangeProvider = ({ children }) => {
  const [dateRanges, setDateRanges] = useState(null)

  useEffect(() => {
    const load = async () => {
      const drs = await allDateRanges()
      // create a map of ids to date range objects
      const drsMap = drs.reduce((acc, curr) => {
        acc[curr.id] = curr
        return acc
      }, {})
      setDateRanges(drsMap)
    }
    load()
  }, [])

  if (!dateRanges) {
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
        <Spinner size={SpinnerSize.SMALL} />
      </div>
    )
  }

  return (
    <DateRangeContext.Provider value={{ dateRanges }}>
      {children}
    </DateRangeContext.Provider>
  )
}

export default DateRangeProvider
