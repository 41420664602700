export const plur = (num, singular, plural) => {
  return num === 1 ? singular : plural
}

// truncates string to length
export const trunc = (input, len) => {
  if (typeof input !== 'string') {
    return input
  }
  return input.substring(0, len)
}

// truncate string and adds ellipsis to end of string if string length surpasses threshold
// else returns string as is if it's at or below threshold
export const ellipse = (input, threshold=10) => {
  if (typeof input !== 'string') {
    return input
  }
  return `${trunc(input, threshold)}${input.length > threshold ? '...' : ''}`
}
