import { useState, useEffect, useContext } from 'react'
import { Collapse, Colors, Divider, Card, Spinner, SpinnerSize } from '@blueprintjs/core'
import { Link, useParams } from 'react-router-dom'
import * as api from '../api'
import paths from '../utils/paths'
import idUtils from '../utils/id'
import useTitle from '../utils/title'
import folioUtils from '../utils/folio'
import sortUtils from '../utils/sort'
import { ManuscriptInfo2 } from './manuscripts'
import { DateRangeContext } from '../contexts/date-range'
import {
  IdTag,
  Title,
  Crumbline,
  MiniInfo,
  MicroInfo,
  MediumInfo,
  HoverCardPill,
  AuthorLink,
  FolioRange,
  Noner,
  BibRefList,
  BibRefCard,
  DateRange,
  Notes,
  CardTitleLink,
  Trunc,
  WorkDate,
  SortSelect,
} from '../components'
import '../components/styles.css'

const worksCrumbs = [
  { href: paths.index(), text: 'Reading the Holy Land'},
  { href: paths.works(), text: 'Works'},
]

export const WorkCard = ({ work, showByline=true, style }) => {
  const cardStyle = {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: `1px solid ${Colors.BLACK}`,
    padding: 0,
  }
  return (
    <Card style={{ ...cardStyle, ...style }}>
      <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
      }}
      >
        <MicroInfo
          title="Title"
          info={
            <Link
              to={paths.works(work.identifier)}
              style={{textDecoration: 'none', fontWeight: 'bold', color: Colors.BLACK}}
            >
              {work.title}
            </Link>
          }
        />
        <MicroInfo
          title="Date"
          style={{textAlign: 'right'}}
          info={
            <DateRange
              start={work.date_start}
              end={work.date_end}
              dateRange={work.date_range ? work.date_range : null}
            />
          }
        />
      </div>
      { showByline &&
        <div
          style={{
            borderTop: `1px solid ${Colors.BLACK}`,
            padding: 0,
          }}
        >
          <div style={{display: 'flex', }}>
            <AuthorLink
              author={work.author}
              name={`by ${work.author.name}`}
              className="hoverable"
              style={{
                padding: '10px 20px 10px 20px',
                fontWeight: 'normal',
                display: 'block',
                borderRight: `1px solid ${Colors.BLACK}`,
              }}
            />
          </div>
        </div>
      }
    </Card>
  )
}

export const Works = () => {
  const [works, setWorks] = useState(null)

  useTitle('Works')

  useEffect(() => {
    api.works({ query: { '_ex': 'author' } }).then(fetchedWorks => setWorks(fetchedWorks))
  }, [])

  let elems = (
    <Spinner size={SpinnerSize.SMALL} />
  )

  if (Array.isArray(works) && works.length > 0) {
    elems = works.map(w => (
      <WorkCard key={w.id} work={w} style={{marginBottom: '2em'}} />
    ))
  } else if (Array.isArray(works) && works.length === 0) {
    elems = <i>No works</i>
  }

  return (
    <div>
      <Crumbline items={worksCrumbs} />
      <Title>Works</Title>
      <div style={{marginTop: '2.5em', marginBottom: '1em'}}>
        <div>{`${works ? works.length : 0} works`}</div>
      </div>
      <div>
        {elems}
      </div>
    </div>
  )
}

const TextUnitWorkCard = ({ textualUnit }) => {
  const tu = textualUnit
  const [selectedTab, setSelectedTab] = useState(null)
  const hasCodicos = Array.isArray(tu.codicos) && tu.codicos.length > 0

  // close tab if it's already open and user clicked the same tab title, else open the new tab
  const toggleTab = tab => setSelectedTab(prev => prev === tab ? null : tab)

  return (
    // the left/right padding is to compensate for the padding on each button group link
    <Card
      style={{
        padding: '20px 0 0 0',
        backgroundColor: 'transparent',
        border: `1px solid ${Colors.BLACK}`,
        boxShadow: 'none',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '3.35fr .25fr .65fr .85fr',
          columnGap: '4px',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingBottom: '20px'
        }}
      >
        <MicroInfo
          title="Shelfmark"
          info={
            <CardTitleLink to={paths.manuscripts(tu.manuscript.identifier)}>
              <Trunc text={tu.manuscript.signature} />
            </CardTitleLink>
          }
        />
        <MicroInfo
          title="Excerpt"
          style={{textAlign: 'right'}}
          info={tu.excerpt ? 'Yes' : 'No'}
        />
        <MicroInfo
          title="Folios"
          style={{textAlign: 'right'}}
          info={
            <FolioRange start={tu.folioStart} end={tu.folioEnd} />
          }
        />
        <MicroInfo
          title="Date"
          style={{textAlign: 'right'}}
          info={
            <span>
              {hasCodicos &&
                tu.codicos.map(c => (
                  <DateRange
                    key={c.id}
                    start={c.date_start}
                    end={c.date_end}
                    dateRange={c.date_range}
                  />
                ))
              }
              {!hasCodicos && <Noner none="Unknown" />}
            </span>
          }
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: `1px solid ${Colors.BLACK}`,
          borderBottom: Boolean(selectedTab) ? `1px solid ${Colors.BLACK}` : null,
        }}
      >
        <div style={{display: 'flex', minHeight: '40px'}}>
          {Boolean(tu.prologue) &&
            <HoverCardPill
              hoverable={Boolean(tu.prologue)}
              onClick={() => toggleTab('incipit-prologue')}
              highlight={selectedTab === 'incipit-prologue'}
            >
              Incipit prologue
            </HoverCardPill>
          }
          <HoverCardPill
            hoverable={Boolean(tu.incipit)}
            onClick={() => toggleTab('incipit')}
            highlight={selectedTab === 'incipit'}
          >
            <Noner none="No incipit">{tu.incipit ? 'Incipit' : null}</Noner>
          </HoverCardPill>
          <HoverCardPill
            hoverable={Boolean(tu.explicit)}
            onClick={() => toggleTab('explicit')}
            highlight={selectedTab === 'explicit'}
          >
            <Noner none="No explicit">{tu.explicit ? 'Explicit' : null}</Noner>
          </HoverCardPill>
          {Boolean(tu.colophon) &&
            <HoverCardPill
              hoverable={Boolean(tu.colophon)}
              onClick={() => toggleTab('colophon')}
              highlight={selectedTab === 'colophon'}
            >
              Colophon
            </HoverCardPill>
          }
          {Boolean(tu.evidence_of_readers) &&
            <HoverCardPill
              hoverable={Boolean(tu.evidence_of_readers)}
              onClick={() => toggleTab('evidence-of-readers')}
              highlight={selectedTab === 'evidence-of-readers'}
            >
              Evidence of readers
            </HoverCardPill>
          }
          {Boolean(tu.notes) &&
            <HoverCardPill
              hoverable={Boolean(tu.notes)}
              onClick={() => toggleTab('notes')}
              highlight={selectedTab === 'notes'}
            >
              Notes
            </HoverCardPill>
          }
        </div>
        <div
          style={{
            display: 'flex',
            borderLeft: `1px solid ${Colors.BLACK}`,
            minHeight: '40px',
          }}
        >
          <ManuscriptInfo2
            contents={tu.manuscript.contents}
            scanLinks={tu.manuscript.scan_link}
          />
        </div>
      </div>
      <Collapse isOpen={Boolean(selectedTab)}>
        <div>
          <MicroInfo
            style={{padding: '20px 20px 14px 20px'}}
            className={selectedTab === 'incipit-prologue' ? 'highlighted-content' : null}
            title="Incipit prologue"
            info={tu.prologue}
          />
          <MicroInfo
            style={{padding: '10px 20px 14px 20px'}}
            className={selectedTab === 'incipit' ? 'highlighted-content' : null}
            title="Incipit"
            info={tu.incipit}
          />
          <MicroInfo
            style={{padding: '10px 20px 14px 20px'}}
            className={selectedTab === 'explicit' ? 'highlighted-content' : null}
            title="Explicit"
            info={tu.explicit}
          />
          <MicroInfo
            style={{padding: '10px 20px 14px 20px'}}
            className={selectedTab === 'colophon' ? 'highlighted-content': null}
            title="Colophon"
            info={tu.colophon}
          />
          <MicroInfo
            style={{padding: '10px 20px 14px 20px'}}
            className={selectedTab === 'evidence-of-readers' ? 'highlighted-content' : null}
            title="Evidence of readers"
            info={tu.evidence_of_readers}
          />
          <MicroInfo
            style={{padding: '10px 20px 24px 20px'}}
            className={selectedTab === 'notes' ? 'highlighted-content' : null}
            title="Notes"
            info={<Notes notes={tu.notes} />}
          />
        </div>
      </Collapse>
    </Card>
  )
}

// generates a fn which gets the lowest start date from a list of codicos
// codicosFn is a getter function that returns the list of codicos from a given object
// dateRanges is a map of all date ranges by date range id
export const startFnMaker = ({ codicosFn, dateRanges }) => obj => {
  const codicos = codicosFn(obj)
  if (!Array.isArray(codicos) || codicos.length === 0) {
    return null
  }
  // go through codicos, find one with lowest start.
  // prefer date_range if present over date_start
  return codicos.reduce((acc, curr) => {
    let nextDate;
    if (Boolean(curr['date_range'])) {
      const dr = curr['date_range']
      if (typeof dr === 'string') {
        const dateRange = dateRanges[dr]
        if (!dateRange) {
          console.warn(`Date range not found: ${dr}`)
          nextDate = null
        }
        nextDate = dateRange.start
      } else {
        nextDate = dr.start
      }
    } else {
      nextDate = curr['date_start']
    }
    return nextDate < acc ? nextDate : acc
  }, Infinity)
}

// generates a fn which gets the highest end date from a list of codicos
// codicosFn is a getter function that returns the list of codicos from a given object
// dateRanges is a map of all date ranges by date range id
export const endFnMaker = ({ codicosFn, dateRanges }) => obj => {
  const codicos = codicosFn(obj)
  if (!Array.isArray(codicos) || codicos.length === 0) {
    return null
  }
  // go through codicos, find one with highest end.
  // prefer date_range if present over date_end
  return codicos.reduce((acc, curr) => {
    let nextDate;
    if (Boolean(curr['date_range'])) {
      const dr = curr['date_range']
      if (typeof dr === 'string') {
        const dateRange = dateRanges[dr]
        if (!dateRange) {
          console.warn(`Date range not found: ${dr}`)
          nextDate = null
        }
        nextDate = dateRange.end
      } else {
        nextDate = dr.end
      }
    } else {
      nextDate = curr['date_end']
    }
    return nextDate > acc ? nextDate : acc
  }, 0)
}

const sortCodicoDate = (dateRanges, tus) => {
  if (!Array.isArray(tus)) {
    return tus
  }

  const startFn = startFnMaker({ codicosFn: tu => tu.codicos, dateRanges })
  const endFn = endFnMaker({ codicosFn: tu => tu.codicos, dateRanges })

  const dateSorter = sortUtils.makeDateSorter({ startFn, endFn })
  const next = [...tus]
  next.sort(dateSorter)
  return next
}

const sortShelfmark = tus => {
  if (!Array.isArray(tus)) {
    return tus
  }
  const next = [...tus]
  next.sort((a, b) => a.manuscript.signature.localeCompare(b.manuscript.signature))
  return next
}

const ManuscriptWitnesses = ({ work }) => {
  const { dateRanges } = useContext(DateRangeContext)
  const [sort, setSort] = useState('Date')
  const [textualUnits, setTextualUnits] = useState(null)

  useEffect(() => {
    if (work) {
      // download all codicos for manuscript and filter the relevant codicos by folio overlaps
      // then set the codicos on the textual unit object so that TextUnitWorkCard can use them
      // (this process is done so that we can sort by date in ManuscriptWitnesses)
      api.textualUnits({ query: { work: work.id, '_ex': 'manuscript' }})
        .then(tus => {
          return Promise.all(tus.map(tu => {
            return api.codicologicalUnits({ query: { manuscript: tu.manuscript.id }})
              .then(allCodicos => {
                const codicos = folioUtils.getContainingRanges({
                  ranges: allCodicos,
                  folioStart: tu.folioStart,
                  folioEnd: tu.folioEnd,
                  startExtractor: cu => cu.folioStart,
                  endExtractor: cu => cu.folioEnd,
                })
                return { ...tu, codicos: codicos }
              })
          }))
        })
        .then(tus => {
          setTextualUnits(tus)
        })
    } else {
      setTextualUnits(null)
    }
  }, [work])

  useEffect(() => {
    if (sort === 'Date') {
      setTextualUnits(prev => sortCodicoDate(dateRanges, prev))
    } else if (sort === 'Shelfmark') {
      setTextualUnits(prev => sortShelfmark(prev))
    }
  }, [sort, textualUnits, dateRanges])

  if (!Array.isArray(textualUnits)) {
    return <Spinner size={SpinnerSize.SMALL} />
  }

  const mss = textualUnits.map(tu => (
    <div key={tu.id} style={{marginBottom: '30px'}}>
      <TextUnitWorkCard textualUnit={tu} />
    </div>
  ))

  const onSortChange = e => {
    setSort(e.target.value)
  }

  return (
    <div>
      <div
        style={{
          marginBottom: '1em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {mss.length} manuscript{mss.length === 1 ? '' : 's'}
        </div>
        <div>
          <SortSelect
            value={sort}
            options={['Date', 'Shelfmark']}
            onChange={onSortChange}
          />
        </div>
      </div>
      <div>{mss}</div>
    </div>
  )
}

export const Work = () => {
  const { wsig } = useParams()
  const [work, setWork] = useState(null)

  const workCrumbs = [
    { href: paths.index(), text: 'Reading the Holy Land'},
    { href: paths.works(), text: 'Works'},
    { text: wsig },
  ]

  useTitle(work ? work.title : 'Work')

  useEffect(() => {
    // when coming back from another Work page (e.g. from pressing back from a url replace),
    // the work may be populated with data from the previous page
    // causing incorrect data to be rendered (or a blank page).
    // Resetting work if wsig changes fixes this.
    setWork(null)
    if (idUtils.isId(wsig)) {
      api.work(wsig).then(w => setWork(w))
    } else {
      api.works({ query: { identifier: wsig } }).then(ws => {
        if (!Array.isArray(ws) || ws.length !== 1) {
          // TODO proper 404s
          return
        }

        const w = ws[0]
        // TODO make this a single query when we're able to expand things properly on general queries (not only id queries)
        api.work(w.id).then(w => setWork(w))
      })
    }
  }, [wsig])

  if (!work) {
    return <Spinner size={SpinnerSize.SMALL} />
  }

  let dateAuthority = null
  if (work.date_authority) {
    dateAuthority = (
      <div>
        <div style={{marginBottom: '1em'}}><strong>Date authority</strong></div>
        <BibRefCard bibref={work.date_authority} />
      </div>
    )
  }

  return (
    <div>
      <Crumbline items={workCrumbs} />
      <Title>{work.title}</Title>
      <div style={{display: 'flex'}}>
        <MiniInfo
          title="Identifier"
          info={<IdTag id={work.identifier} />}
          style={{marginLeft: '0'}}
      />
        <Divider />
        <MiniInfo title="Author" info={<AuthorLink author={work.author} />} />
        <Divider />
        <MiniInfo
          title="Date"
          info={<WorkDate work={work} />}
          moreInfo={dateAuthority}
          none="Unknown"
        />
        <Divider />
        <MiniInfo title="Redaction" info={work.redaction} />
      </div>
      {work.summary ? <MediumInfo info={<Notes notes={work.summary} />} /> : null}
      <MediumInfo title="Incipit prologue" info={work.prologue} />
      <MediumInfo title="Incipit text" info={work.incipit} />
      <MediumInfo title="Explicit text" info={work.explicit} />
      <MediumInfo title="Notes" info={<Notes notes={work.notes} />} />
      <MediumInfo
        title="Manuscript witnesses"
        info={<ManuscriptWitnesses work={work} />}
        style={{maxWidth: 'inherit'}}
      />
      <MediumInfo
        title="Editions"
        info={
          <BibRefList
            bibrefIds={Array.isArray(work.edition) ? work.edition.map(e => e.id) : null}
            none="No editions"
          />
        }
      />
      <MediumInfo
        title="Studies"
        info={
          <BibRefList
            bibrefIds={Array.isArray(work.study) ? work.study.map(s => s.id) : null}
            none="No studies"
          />
        }
      />
    </div>
  )
}
