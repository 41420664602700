import paths from '../utils/paths'
import { Link } from 'react-router-dom'
import { Divider, Colors } from '@blueprintjs/core'
import { Crumbline } from '../components'
import styles from '../components/styles.css'

const Index = () => {
  return (
    <>
      <Crumbline showCrumbs={false} />
      <div style={{fontFamily: 'Domine', fontSize: '30px', fontWeight: 700}}>
        <div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <h1
              style={{
                textAlign: 'left',
                lineHeight: 1,
                fontSize: '72px',
                fontFamily: 'Domine',
                color: Colors.BLACK,
                letterSpacing: '1px',
              }}
            >
              Reading <span style={{fontSize: '56px'}}>the</span>
              <div style={{textAlign: 'left'}}>Holy Land</div>
            </h1>
          </div>
          <div style={{marginTop: '40px'}}>
            <div style={{marginBottom: '25px', display: 'grid', gridTemplateColumns: '1fr', columnGap: '1fr'}}>
              <div className="homeLink">
                <Link className="link" to={paths.works()}>Works</Link>
              </div>
            </div>
            <div
              style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'space-between', gap: '25px'}}
            >
              <div className="homeLink">
                <Link className="link" to={paths.authors()}>Authors</Link>
              </div>
              <div className="homeLink">
                <Link className="link" to={paths.manuscripts()}>Manuscripts</Link>
              </div>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', marginTop: '60px'}}>
            <img src="/rthl-logo.png" height="300px" />
          </div>
        </div>
        <div>
          <div style={{marginTop: '60px'}}>
            <div
              className="horiz"
              style={{
                fontSize: '24px',
                display: 'block',
                boxSizing: 'border-box',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <span
                style={{
                  paddingRight: '20px',
                  display: 'inline-block',
                  background: 'rgb(250, 240, 216)',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                Appendix
              </span>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                marginTop: '30px',
                gap: '25px',
                marginBottom: '140px',
              }}
            >
              <div className="homeLink">
                <Link className="link" to={paths.bibliographies()}>Bibliography</Link>
              </div>
              <div className="homeLink">
                <Link className="link" to={paths.catalogues()}>Catalogues</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
