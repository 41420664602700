import { Colors } from '@blueprintjs/core'

// #729B79 - cambridge blue / #227C9D - cerulean / #084887 - polynesian blue
// #DE541E - flame / #AD343E - cardinal
// #494331 - drab brown brown
// #3F3F37 - black olive
// #495D63 - payne's gray
// yellowish - rgb(255, 252, 225)
// #DE6B48 - burnt sienna

const border = `1px solid ${Colors.BLACK}`

export const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: 'transparent',
        left: 0,
        bottom: 0,
        maxWidth: '1024px',
        margin: 'auto',
        paddingTop: '25px',
        paddingBottom: '60px',
      }}
    >
      <div style={{borderTop: border, paddingTop: '20px'}} >
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px'}}>
          <div
            style={{fontFamily: 'Domine', fontSize: '32px'}}
          >
            <span>Reading <span style={{fontSize: '22px'}}>the</span></span>
            <div>Holy Land</div>
          </div>
          <div style={{display: 'flex'}}>
            <div style={{marginRight: '1em'}}>
              <img src="/lsf-logo-3.png" height="50px"/>
            </div>
            <div>
              <img src="/biu-logo.png" height="50px"/>
            </div>
          </div>
        </div>

        <div style={{borderTop: border, paddingTop: '30px', paddingBottom: '15px'}}>
          <div style={{textAlign: 'right'}}>
            <a href="#" style={{textDecoration: 'none', color: Colors.BLACK}}>↑ <br/> Back to top</a>
          </div>
        </div>
        <div
          style={{
            borderTop: border,
            borderBottom: border,
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          Reading the Holy Land © 2024
        </div>
      </div>
    </div>
  )
}

export default Footer
