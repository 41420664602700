import useTitle from '../utils/title'
import { Colors } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import paths from '../utils/paths'

import { Title, Crumbline } from '../components'

const crumbs = [
  { href: paths.index(), text: 'Reading the Holy Land'},
  { text: 'About'},
]

export const About = () => {
  useTitle('About')
  return (
    <div>
      <Crumbline items={crumbs} showAbout={false} />
      <Title>About</Title>
      <div>
        <p>
          Ever since the conversion of the Roman Empire to Christianity, the Holy
          Land has held a special place in the hearts and minds of Latin
          Christians. One tangible illustration of its significance is to be found in
          the many geographic accounts written about the region. During the
          Frankish rule of the Holy Land (1099-1291), a particularly large number
          of Latin descriptions of this territory were composed, many of which
          have come down to us through hundreds of manuscripts. This textual
          corpus is generally well-known to scholars. However, given that the last
          comprehensive survey was published in 1890 by Reinhold Röhricht in
          his <i>Bibliotheca Geographica Palaestinae</i>, we felt that the scholarly
          community would benefit from an updated resource, which would
          include, for example, texts and manuscripts which were unknown at
          the time the <i>Bibliotheca</i> was published. Utilizing tools unavailable to
          Röhricht, we planned and built a database which is searchable by
          different parameters, and which includes details such as the date and
          geographical provenance of the different manuscript witnesses, as well
          as comments concerning their colophons and glosses. We quickly
          learned just how much work was needed in order to provide full,
          updated, information about both the texts in this corpus and the
          manuscripts through which they are transmitted.
        </p>

        <p>
          By definition, this kind of project can have no clear end point.
          Manuscripts and even texts continue to surface, and there are always
          new studies being published that shed light on manuscripts, works and
          authors belonging to this corpus. In this sense, our database is not fully
          complete and is certainly not perfect. It remains a work in progress that
          we are constantly updating. And yet, we think that at this point it can
          already serve as an important tool for scholars interested in the corpus.
          We are therefore happy to make the database available and share it
          with you. We do have two requests from you, <i>lectores carissimi</i>:

          <ol>
            <li style={{marginBottom: '1em'}}>If you find any errors, anything that does not work, or that is confusing, or if you think of a useful feature we might have overlooked, please let us know at <a href="mailto:readingtheholyland@gmail.com" style={{textDecoration: 'none', fontWeight: 'bold', color: Colors.BLACK}}>readingtheholyland@gmail.com</a>.</li>
            <li>If you find our database useful in a way that contributes to your own work, please do cite it according to the <Link to={paths.citation()} style={{textDecoration: 'none', fontWeight: 'bold', color: Colors.BLACK}}>citation guidelines</Link> which you will find on our homepage.</li>
          </ol>
        </p>
        <p style={{marginTop: '2.5em', float: 'right'}}>
          <i>- The members of the RTHL team</i>
        </p>
      </div>
    </div>
  )
}
